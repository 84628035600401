import React from "react";
import { Helmet } from 'react-helmet';
import { Card, CardHeader, CardTitle, CardFooter } from "reactstrap";
import Wrapper from '../_components/Wrapper'
class CalendarCalendar extends React.Component {

    render() {

        return (

            <Wrapper>

                <Helmet>
                    <title>Get In Touch</title>
                    <meta name="description" content="Get In Touch" />
                </Helmet>

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Talk To Fairmax Law</CardTitle>
                    </CardHeader>

                    <CardHeader className="bg-secondary">
                        <p className="text-sm mb-0">
                            Hello! We look forward to talking with you. Please feel free to give us a call anytime at the number below
                        </p>
                    </CardHeader>

                    <CardFooter className="bg-secondary">
                        <p className="text-sm mb-0">By calling below you authorize Fairmax Law to give you a call at your preferred phone number as well as send appointment reminders via text message and email. </p>
                    </CardFooter>

                    <CardFooter className="text-center">
                        (888) 324-6279
                    </CardFooter>

                </Card>

            </Wrapper>


        );
    }
}

export default CalendarCalendar
