import apiWorker from '../apiWorker'

export default {

    findById              : (template_docs_sent_id, hideError) => apiWorker('get',  `/api/v1/core/template_docs_sent/findById/${template_docs_sent_id}?safe=true`, null, hideError),
    validateAccessCode    : (data)                             => apiWorker('post', `/api/v1/core/template_docs_sent/validate_access_code`, data),
    build                 : (data)                             => apiWorker('post', `/api/v1/core/template_docs_sent/build`, data),
    sign                  : (data)                              => apiWorker('post', `/api/v1/core/template_docs_sent/sign`, data),

}
