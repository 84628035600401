import React, { Component } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Form, FormGroup } from 'reactstrap';

import Wrapper from '../_components/Wrapper';
import Circle from 'components/markup/loading/Circle';

import _contacts from '_functions/contacts';
import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';


class OptOutEmail extends Component {


    state = {
        contact: null,
        error: false,
        loading: true
    };

    onInputToggle = (stateName) => {

        const contact = Object.assign({}, this.state.contact)
        contact[stateName] = !contact[stateName]

        this.setState({contact})

    }

    onSubmit = async (e) => {

        e.preventDefault();

        toggleStandardLoader(true)

        const updated = await _contacts.updateOptOut(this.state.contact._id, {
            opt_out_email: this.state.contact.opt_out_email,
            opt_out_text: this.state.contact.opt_out_text,
        })

        if(updated.success) {
            toggleAlertBS(false, 'Your preferences have been updated.')
        } else {
            toggleAlertBS(true, 'Something went wrong updating your preferences')
        }

        toggleStandardLoader(false)

    }

    componentDidMount = async () => {

        // secure url by deleting the identifier
        window.history.pushState('', '', '/actions/opt_out/secure')

        const identifier = this.props.match.params.identifier.replace()

        let foundContact;

        if(identifier === 'secure') {
            return this.setState({error: true, loading: false})
        }

        foundContact = await _contacts.fetchByIdentifier(identifier, true)

        if(foundContact && foundContact.success && foundContact.data) {
            this.setState({contact: foundContact.data, loading: false})
        } else {
            this.setState({error: true, loading: false})
        }

    }

    render() {

        const { contact, loading, error } = this.state

        if(loading) return <Wrapper />

        if(error) return (
            <Wrapper>
                <p className="text-sm">
                    <i className="fas fa-exclamation-triangle text-warning mr-2" />
                    This page has expired for security reasons
                </p>
            </Wrapper>
        )

        return (

            <Wrapper>

                <h2>Messaging Preferences</h2>
                <p className="text-sm">To manage the emails and texts you receive from Fairmax Law change your settings below.</p>

                {contact ? (
                    <Card>

                        <CardHeader>
                            <CardTitle className="mb-0">My Settings</CardTitle>
                        </CardHeader>

                        <CardBody>
                            <Form>

                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="opt-out-email"
                                            type="checkbox"
                                            checked={!contact.opt_out_email}
                                            onClick={() => this.onInputToggle('opt_out_email')}
                                            onChange={() => {}}
                                        />
                                        <label className="custom-control-label" htmlFor="opt-out-email">
                                            <i className="fas fa-envelope text-success mr-2" /> Receive Email Communication
                                        </label>
                                    </div>
                                </FormGroup>

                                <FormGroup className="mb-0">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="opt-out-phone"
                                            type="checkbox"
                                            checked={!contact.opt_out_text}
                                            onClick={() => this.onInputToggle('opt_out_text')}
                                            onChange={() => {}}
                                        />
                                        <label className="custom-control-label" htmlFor="opt-out-phone">
                                            <i className="fas fa-phone text-success mr-2" /> Receive Phone Communication
                                        </label>
                                    </div>
                                </FormGroup>

                            </Form>
                        </CardBody>

                        <CardFooter className="text-right">
                            <button onClick={(e) => this.onSubmit(e)} className="btn btn-outline-warning">Update Settings</button>
                        </CardFooter>

                    </Card>
                ) : (
                    <Card style={{height: 155, paddingTop: 50}}><Circle /></Card>
                )}

                <p className="text-sm">** Note that if you are a current client you may still receive reminders and notifications regardless of the settings above</p>


            </Wrapper>

        )

    }

}

export default OptOutEmail;
