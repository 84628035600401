import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {  Card, CardBody, CardHeader, Container } from "reactstrap";
import { formatMoney } from 'utils/currency';
import _invoices from '_functions/invoices';
import Circle from 'components/markup/loading/Circle';
import InvoicePreview from './InvoicePreview'

class BillingInvoices extends React.Component {

    state = {
        payments: null,
        showModal: false,
        invoiceToShow: {}
    }

    columns = [
        {
            dataField: "date",
            text: "Date Sent",
            formatter: (cell, row) => (
                <span>
                    {moment.unix(row.date).format('MMMM Do, YYYY')} <br />
                    {moment.unix(row.date).format('h:mm A')}

                </span>
            ),
        },
        {
            dataField: "case",
            text: "Case",
            formatter: (cell, row) => (
                <span className="text-capitalize">{row.case ? row.case.name : 'Case Not Found'}</span>
            )
        },
        {
            dataField: "total_paid",
            text: "Amount Paid",
            formatter: (cell, row) => {
                return formatMoney(row.total_paid)
            }
        },
        {
            dataField: "total",
            text: "Invoice Total",
            formatter: (cell, row) => {
                return formatMoney(row.total)
            }
        },
        {
            dataField: "type",
            text: "",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => (
                <div className="text-right">
                    <button
                        onClick={() => this.setState({showModal: true, invoiceToShow: row})}
                        className="btn btn-success"
                    >
                        View Invoice
                    </button>
                </div>
            )
        },

    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: false,
        withFirstAndLast: false,
        sizePerPageRenderer: () => false
    });

    componentDidMount = async () => {

        let invoices = await _invoices.findByContact(this.props.viewing_user._id)


        if(invoices.success) {

            invoices = invoices.data.filter(i => i.status === 'sent')
            this.setState({invoices})
        }

    }

    render() {

        const { invoices, showModal, invoiceToShow } = this.state

        if(!invoices) return <div className="py-6"><Circle /></div>

        return (
            <>

            <Container>

                <Helmet>
                    <title>Invoices</title>
                    <meta name="description" content="Invoices" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Billing</span>} breadcrumb_1="Invoices"
                />


                <Card>

                    <CardHeader>
                        <h3 className="mb-0">All Case Invoices</h3>
                    </CardHeader>

                    { invoices && invoices.length ? (

                        <>
                        <ToolkitProvider
                            data={invoices}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <div className="table-not-fixed table-responsive table-vertical-align">
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>
                            )}

                        </ToolkitProvider>

                        <InvoicePreview
                            invoice={invoiceToShow}
                            showModal={showModal}
                            toggle={() => this.setState({showModal: !showModal})}
                        />
                        </>
                    ) : (
                        <CardBody>
                            <Link to="/dashboard/contacts/create">
                                There have been no payments created for your account.
                            </Link>
                        </CardBody>
                    )}

                </Card>

            </Container>

            </>
        );
    }
}



const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(BillingInvoices);

