import PropTypes from 'prop-types'
import keys from 'keys';
import React from "react";
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { toggleStandardLoader } from 'store/functions/system/system';
import { renderPreview } from '../_functions/renderUpload';

import ModalConfirm from '../Modals/Confirm';
import ModalDisclosures from '../Modals/Disclosures';

class SignPreview extends React.Component {

    state = {
        loaded: false,
        pdfDoc: null,
        isResizing: false,
        showModalConfirm: false,
        showModalDisclosures: false,
        renderedPages: <div />,
        images: [],
    }

    windowWidth = window.innerWidth;

    onSign = () => {
        this.toggleModalConfirm();
        this.props.onSign()
    }

    toggleModalConfirm  = () => {
        this.setState({showModalConfirm: !this.state.showModalConfirm});
    }

    toggleModalDisclosures  = () => {
        this.setState({showModalDisclosures: !this.state.showModalDisclosures, showModalConfirm: !this.state.showModalConfirm});
    }

    onImgLoaded = (img, page) => {
        const images = [...this.state.images]
        images[page] = img;
        this.setState({images})
    }


    setImagesToMarkup = () => {

        const markup = [];

        this.state.images.forEach((image, i) => {
            markup.push((
                <div key={i}>
                    <p className="text-white text-sm text-right mb-0">Page {i + 1} / 46</p>
                    <div id={`pdf-canvas-page-${i + 1}-wrapper`} data-pdf-page-wrapper="true" style={{position: 'relative', width: '100%'}}>
                        <img id={`pdf-canvas-page-${i + 1}`} className="z-depth-3 mb-3 rounded" data-page={i + 1} src={image} alt={`page ${i + 1}`} style={{width: '100%'}} />
                    </div>
                </div>
            ))
        })

        this.setState({renderedPages: markup,})

    }

  
    componentDidMount = async () => {

        window.scrollTo({top: 0, left: 0})

        document.body.classList.add('noScroll')

        renderPreview(this.props.previewData, this.onImgLoaded, (pdfDoc) => {
            this.setState({loaded: true, pdfDoc})
            toggleStandardLoader(false);
            document.body.classList.remove('noScroll')
        
            this.setImagesToMarkup()

        })

    }

    render() {

        const { loaded, isResizing, showModalConfirm, showModalDisclosures, renderedPages } = this.state;
        const { onBackToSign, initialPages } = this.props;

        return (

            <>

                <Card className="bg-dark" style={{marginBottom: 5}}>
                    <CardBody>
                        <Row>
                            <Col xs={6} className="align-self-center">
                                <img src={keys.LOGO} alt="Logo" style={{maxWidth: '100%', width: 200}} />
                            </Col>
                            <Col xs={6} className="text-right align-self-center">
                                <h2 className="text-white  mb-0">Confirmation</h2>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>


                <Card>
                    <CardBody>
                        <p className="text-sm text-center text-sm mb-0">You may preview the document to be signed below. When you have confirmed everything is correct click <b className="font-weight-bold text-underline">"Sign My Document"</b> on the bottom of your screen.</p>
                    </CardBody>
                </Card>
                
                <Container className="archk-template-docs-container pb-6">
                    <div className="archk-template-docs"  >

                        <div className="archk-template-docs-main">
                            <div style={{height: 'auto',  maxWidth: '100%',  margin: 'auto'}} >

                                {isResizing ? null : (
                                    <div id="canvas-wrapper" className="position-relative" style={{opacity: loaded ? 1 : 0}}>
                                        <div id="pdf-canvas" className="position-relative" >
                                            {loaded ? renderedPages : <></>}
                                        </div>
                                    </div>
                                )}

                            </div>

                            <div className="doc-progress bg-dark text-center">
                                <Row>
                                    <Col xs={4}>
                                        <button onClick={onBackToSign} style={{maxWidth: 300}} className="btn btn-block btn-outline-warning"><i className="fas fa-arrow-left mr-2" />Back</button>
                                    </Col>
                                    <Col xs={8} className="text-right">
                                        <span className="position-relative d-inline-block">
                                            <button onClick={this.toggleModalConfirm} className="btn btn-success btn-block" style={{maxWidth: 400, marginLeft: 'auto' }}>Sign My Document! </button>
                                            <i className="fas fa-arrow-down confirmation-arrow " />

                                        </span>
                                       
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </div>
                </Container>

                <div id="canvas-anchor" style={{display: 'none'}} />


                <ModalConfirm 
                    showModalConfirm={showModalConfirm}
                    toggleModalConfirm={this.toggleModalConfirm}
                    toggleModalDisclosures={this.toggleModalDisclosures}
                    onSign={this.onSign}
                    initialPages={initialPages}
                />
     
                <ModalDisclosures 
                    showModalDisclosures={showModalDisclosures}
                    toggleModalDisclosures={this.toggleModalDisclosures}
                />
     

            </>

        );
    }
}

SignPreview.propTypes = {
    previewData   : PropTypes.string.isRequired,
    onSign     : PropTypes.func.isRequired
}

export default SignPreview
