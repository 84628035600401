/*
Documentation

this page holds all the routes for the dashboard layout

*/


import AccountSettings          from '../pages/account/Settings';
import Dashboard                from '../pages/dashboard/index';
import DashboardDocumentSelect  from '../pages/dashboard/DocumentSelect';

import CasesView                from '../pages/cases/View';
import CasesDocuments           from '../pages/cases/Documents';

import CalendarCalendar         from '../pages/calendar/Calendar';

import ContactAlertsAll         from '../pages/contact_alerts/All';

import BillingPay               from '../pages/billing/Pay';
import BillingPayments          from '../pages/billing/Payments';
import BillingMethods           from '../pages/billing/Methods';
import BillingInvoices          from '../pages/billing/Invoices';

export default () => {

    return[



        /***********************
        Dashboard
        ************************/
        {
            path: "/",
            component: Dashboard,
        },

        {
            path: "/document_select",
            component: DashboardDocumentSelect,
        },

        /***********************
        Billing
        ************************/
        {
            path: "/billing/invoices",
            component: BillingInvoices,
        },
        {
            path: "/billing/payments",
            component: BillingPayments,
        },
        {
            path: "/billing/methods",
            component: BillingMethods,
        },

        {
            path: "/billing/pay/:_id",
            component: BillingPay,
        },

        /***********************
        Cases
        ************************/
        {
            path: "/cases/view/:_id",
            component: CasesView,
        },
        {
            path: "/cases/view/:_id/documents",
            component: CasesDocuments,
        },

        /***********************
        Calendar
        ************************/
        {
            path: "/calendar/calendar",
            component: CalendarCalendar,
        },

        /***********************
        Contact Alerts
        ************************/
        {
            path: "/alerts/all",
            component: ContactAlertsAll,
        },

        /***********************
        ACCOUNT
        ************************/
        {
            path: "/settings",
            component: AccountSettings,
        },

    ]

}
