/*
Documentation

This page shows all elements for letting a user view a case billing, show invoices for that case, and make a payment on their case

*/

import Circle from 'components/markup/loading/Circle';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Container, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';

import BillingOverview from './components/BillingOverview';
import ChargeOneTimePayment from './components/ChargeOneTimePayment';
import HasNoVaults from './components/HasNoVaults';
import Invoices from './components/Invoices';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { Helmet } from 'react-helmet';
import { Redirect, Link } from 'react-router-dom';

import _cases from '_functions/cases';
import _invoices from '_functions/invoices';
import _payments from '_functions/payments';

class CaseView extends Component {

    state = {
        navigation: 0,
        canSave: true,
        total_paid: null,
        payments: null,
        invoices: null,
        case: null
    };

    //scroll window to top
    scrollToTop = () => window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    onChangeNavigation = (navigation) => {

        this.setState({navigation})
        this.scrollToTop()

    }

    fetchInvoices = async () => {

        const invoices = await _invoices.findByCase(this.props.match.params._id)

        if(invoices.success) {
            const data = invoices.data.filter(i => i.status === 'sent')
            this.setState({invoices: data})
        }

    }


     //this function finds all payment methods associated with the user, will create contact if they don't exist
    getPaymentMethods = async  () => {

        const contact = this.props.viewing_user;
        const fortisContactExists = await _payments.contacts.findOrCreate(contact)

        if(fortisContactExists.success) {

            this.setState({account_vaults: fortisContactExists.data.account_vaults})

        }

    }

   //find all payments that occurred for this case
   findLogsByCase = async () => {

        const payments = await _payments.findByCase(this.props.match.params._id)

        if(payments.success) {

            this.setState({
                payments: payments.data.payments,
                total_paid: payments.data.total_paid
            })

        }

    }

    listenForPaymentsAdded = (data) => {

        const payment_case_id = data.data.case;

        if(payment_case_id === this.props.match.params._id) {
            this.findLogsByCase()
            this.fetchInvoices()
        }

    }

    componentDidMount = async () => {

        const _case = await _cases.findById(this.props.match.params._id)

        if(_case.success) {

            this.setState({case: _case.data})

            this.fetchInvoices()
            this.getPaymentMethods()
            this.scrollToTop()
            this.findLogsByCase()

        }

    }


    render() {

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        const _case = this.state.case
        const { navigation, invoices, account_vaults, total_paid } = this.state;

        const has_vaults = account_vaults && account_vaults.length ? true : false;

        //if we dont have the data we need show loading circle
        if(!_case || !account_vaults || !invoices) {

            return <div className="py-6"> <Circle /> </div>

        }

        return (

            <Container id="billing-center-content">

                <Helmet>
                    <title>Billing Overview</title>
                    <meta name="description" content="Billing Overview" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Billing</span>}
                    breadcrumb_1="Cases"
                    breadcrumb_2={<span className="text-capitalize">{_case.name}</span>}
                    actionComponent={(
                        <Link to={`/dashboard/cases/view/${_case._id}`} className="btn btn-outline-success">
                            <i className="fas fa-arrow-left mr-2" />View Case
                        </Link>
                    )}
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Help Center</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm mb-0">
                            On this page you can see your total billables for the associated case, view all invoices issued, and add a payment to your remaining balance. To view the details of an specific invoice <Link className="text-underline font-weight-bold" to="/dashboard/billing/invoices">click here</Link>
                        </p>
                    </CardBody>

                </Card>

                <Row>

                    <Col md={3}>

                        <ul className="pl-0" style={{listStyle: 'none'}}>
                            <li className={navigation === 0 ? 'cursor-pointer bg-success text-white p-3': 'cursor-pointer p-3'} onClick={() => this.onChangeNavigation(0)}>
                                Overview
                            </li>

                            <li className={navigation === 1 ? 'cursor-pointer bg-success text-white p-3': 'cursor-pointer p-3'} onClick={() => this.onChangeNavigation(1)}>
                                Case Invoices
                            </li>
                            <li className={navigation === 2 ? 'cursor-pointer bg-success text-white p-3': 'cursor-pointer p-3'} onClick={() => this.onChangeNavigation(2)}>
                                Make A Payment
                            </li>
                        </ul>

                    </Col>



                    <Col md={9}>

                        {navigation === 0 && (
                            <BillingOverview
                                total_paid={total_paid}
                                case={_case}
                                invoices={invoices}
                            />
                        )}


                        {navigation === 1 && (
                            <Invoices
                                invoices={invoices}
                                onInvoiceSent={(sent_invoice) => {}}
                            />
                        )}

                        {navigation === 2 && (

                            invoices && invoices.length ? (
                                <>

                                    {has_vaults ? (
                                        <ChargeOneTimePayment
                                            invoices={invoices}
                                            case={_case}
                                            account_vaults={account_vaults}
                                        />
                                    ) : (
                                        <HasNoVaults />
                                    )}
                                </>
                            ) : (
                                <div className="alert alert-primary">
                                    There are no invoices associated with this case.
                                </div>
                            )

                        )}

                    </Col>

                </Row>

            </Container>

        )

    }

}


const mapStateToProps = state => {
    return {
      viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(CaseView);
