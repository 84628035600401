/*
Documentation

this component create the breadcrumb header for the dashboard/admin pages

*/

import PropTypes from "prop-types";
import React from "react";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";
import { Link } from 'react-router-dom';

const DashHeader = (props) => {


    return (
        <div className="header">

                <div className="header-body">
                    <Row className="align-items-center py-4">

                        <Col lg="6" xs="7" >
                            <h6 className="h2 d-inline-block mb-0 ">{props.title}</h6>{" "}
                            <Breadcrumb
                                className="d-none d-md-inline-block ml-md-4"
                                listClassName="breadcrumb-links"
                            >

                                {props.icon &&
                                    <BreadcrumbItem>
                                        <Link to={props.icon_link}>
                                            <i className={props.icon} />
                                        </Link>
                                    </BreadcrumbItem>
                                }

                                {props.breadcrumb_1 &&
                                    <BreadcrumbItem aria-current="page" className="active">
                                    {props.breadcrumb_1}
                                    </BreadcrumbItem>
                                }

                                {props.breadcrumb_2 &&
                                    <BreadcrumbItem aria-current="page" className="active">
                                    {props.breadcrumb_2}
                                    </BreadcrumbItem>
                                }

                                {props.breadcrumb_3 &&
                                    <BreadcrumbItem aria-current="page" className="active">
                                    {props.breadcrumb_3}
                                    </BreadcrumbItem>
                                }
                            </Breadcrumb>
                        </Col>

                        {props.actionComponent &&
                            <Col className="text-right " lg="6" xs="5">
                               {props.actionComponent}
                            </Col>
                        }



                    </Row>
                </div>

        </div>
    );

}


DashHeader.propTypes = {
  title: PropTypes.element.isRequired,
  breadcrumb_1: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  breadcrumb_2: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  breadcrumb_3: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  actionComponent: PropTypes.element,
  icon: PropTypes.string,
  icon_link: PropTypes.string,
};

export default DashHeader;
