/*
Documentation

this page holds all of our layouts and functions
that need to fire before the app runs

*/

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { setViewingUser } from 'store/functions/auth/auth';

import Auth from 'views/auth/layout';
// import Dashboard from 'views/dashboard/layout';
import Portal from 'views/portal/layout';
import Errors from 'views/errors/layout';
import Actions from 'views/actions/layout';
import FailedConnection from 'views/errors/pages/errors/FailedConnection';
import LoginAsClient from 'views/system/LoginAsClient';

import SystemAlertBS from 'components/markup/system/AlertBS'
import SystemAlert from 'components/markup/system/Alert'
import SystemLoader from 'components/markup/system/Loader'
import StandardLoader from 'components/markup/system/StandardLoader'
import { initWorker } from 'utils/pdf2/controller'

class App extends Component {

    state = {
        loaded: false,
        error: false
    };

    componentDidMount = async () => {

        initWorker()

        //run all promises that need to fire before the
        //app loads here
        Promise.all([

            setViewingUser(),

        ]).then((values) => {

            let errors = 0;

            values.forEach(v => {
                if(v.success === false) {
                    errors++;
                }
            })

            this.setState({loaded: true, error: errors ? true : false})

        })

    }

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    render() {

        if(this.state.shouldRedirect) {
            return window.location.href = this.state.shouldRedirect
        }

        if(!this.state.loaded) {
            return <div></div>
        }

        if(this.state.error) {
            return <FailedConnection />
        }

        return (

            <>

                <SystemAlertBS />
                <SystemAlert />
                <SystemLoader />
                <StandardLoader />

                <BrowserRouter>
                    <Switch>

                        <Route path="/auth" render={props => <Auth {...props} /> } />
                        {/* <Route path="/dashboard" render={props => <Dashboard {...props} /> } /> */}
                        <Route path="/dashboard" render={props => <Portal {...props} /> } />
                        <Route path="/actions" render={props => <Actions {...props} /> } />
                        <Route path="/errors" render={props => <Errors {...props} /> } />
                        <Route path="/system/auth/:user/:contact" render={props => <LoginAsClient {...props} /> } />

                        <Route path="/" render={props => <Redirect to="/auth/login" /> } />

                    </Switch>
                </BrowserRouter>

            </>

        )

    }

}

export default App
