import React from 'react'

const LoadingCircle = () => {

    return (
        <div className="text-center">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    );

}

export default LoadingCircle;