import { architeckCall } from 'database';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { setViewingUserFromAdmin } from 'store/functions/auth/loginAsAdmin'
import { Container, Input, FormGroup, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { toggleStandardLoader } from 'store/functions/system/system'
import  base64 from 'base-64'

class Overview extends Component {


    state = {
        password: null
    };

    loginAsContact = async () => {

        toggleStandardLoader(true)

        this.setState({unauthorized: false})

        const user = base64.decode(this.props.match.params.user);
        const contact =  base64.decode(this.props.match.params.contact);

        const authenticate = await architeckCall({
            shouldNotShowAndLogError: true,
            method: 'post',
            url: '/api/v1/auth/contact/loginAsContact',
            data: {
                contact_id: contact,
                user_id: user,
                password: this.state.password
            }
        })

        if(authenticate.success) {

            setViewingUserFromAdmin(authenticate.data)
            this.setState({shouldRedirect: '/dashboard'})

        } else {

            this.setState({unauthorized: true})

        }

        toggleStandardLoader(false)

    }

    componentDidMount = () => {

        // remove user and contact id's (base64 encoded) from the url. Prevents page refreshs
        const splitURL = window.location.href.split('/')
        let url = ''

        splitURL.forEach((string, i) => {

            if(i < (splitURL.length - 2))
            url += string + '/'

        })

        window.history.pushState(null, null, url);

    }

    render() {

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />


        return (

            <Container className="py-6" style={{maxWidth: 400}}>

                {this.state.unauthorized ? (
                    <div className="alert alert-danger"><i className="fas fa-exclamation-triangle mr-2" />Unauthorized!</div>
                ) : null}

                <Card >

                    <CardHeader>
                        <CardTitle className="mb-0">System Auth</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <FormGroup>
                            <label className="form-control-label">Enter Your Password</label>
                          
                            <Input
                                type="password"
                                value={this.state.password || ''}
                                onChange={(e) => this.setState({password: e.target.value})}
                            />
                            
                            <p className="text-sm text-warning font-weight-bold mt-3 mb-0">
                                This will log out your account and log you in as a contact.
                            </p>

                        </FormGroup>
                    </CardBody>

                    <CardFooter className="text-right">
                        <button onClick={() => this.loginAsContact()} className="btn btn-outline-danger">Authenticate</button>
                    </CardFooter>

                </Card>



            </Container>

        )

    }

}

export default Overview;
