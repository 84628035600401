/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';
import store from 'store';

const initialState = {
    all_functions_run: false,
    function_total: 9,
    functions_run: 0,
    alerts: {
        show:     false,
        text:     null,
        type:     null,
    },
    loader: false,
    standardLoader: false,
    alertBS: {
        error: false,
        text: null
    },
    documents_loading: []
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.TOGGLE_SYSTEM_ALERT:

            return {
                ...state,
                alerts: {
                    show: action.payload.boolean,
                    text: action.payload.text,
                    type: action.payload.type,
                }
            }

        case actionTypes.TOGGLE_SYSTEM_LOADER:

            return {
                ...state,
                loader: action.payload,
            }

        case actionTypes.TOGGLE_STANDARD_LOADER:

            return {
                ...state,
                standardLoader: action.payload,
            }

        case actionTypes.TOGGLE_SYSTEM_ALERT_BS:

            if(action.payload && action.payload.error !== undefined && action.payload.text) {

                return {
                    ...state,
                    alertBS: {
                        error: action.payload.error,
                        text: action.payload.text,
                    }
                }

            } else {

                return {
                    ...state,
                    alertBS: null
                }

            }

        case actionTypes.SET_DOCUMENT_LOADING:

            const payload = action.payload
            const documents_loading = [...state.documents_loading]

            if(payload.status === 'starting') {

                // if fileId is already in state it means this is a chunk, we don't need to do anything with it
                if(!documents_loading.some(el => el.fileId === payload.fileId)) {

                    documents_loading.push({
                        status        : payload.status,
                        fileName      : payload.fileName,
                        fileId        : payload.fileId,
                        started       : payload.timestamp,
                        size          : payload.size,
                        finishToken   : payload.finishToken,
                        file          : payload.file,
                        responseText  : payload.responseText,
                        onUpload      : payload.onUpload,
                        onError       : payload.onError,
                        onFinalUpload : payload.onFinalUpload,
                        batchNumber   : payload.batchNumber,
                        batchTotal    : payload.batchTotal,
                    })
                    
                }

            } else {

                const fileIndex = documents_loading.findIndex(el => el.fileId === payload.fileId)


                if(fileIndex !== -1) {

                    if(payload.status !== 'remove') {

                        documents_loading[fileIndex] = {
                            ...documents_loading[fileIndex],
                            status: payload.status,
                            ended: payload.timestamp
                        }

                        // in 5 seconds remove the file from the success or error category
                        setTimeout(() => {
                            store.dispatch({ type: actionTypes.SET_DOCUMENT_LOADING, payload : {fileId: payload.fileId, status: 'remove'} });
                        }, 5000)

                    } else {
                        documents_loading.splice(fileIndex, 1)
                    }

                }

            }

            return { ...state, documents_loading }

        default:

            return state;

    }

}

export default reducer;
