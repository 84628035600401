/*
Documentation

*/

import PropTypes from 'prop-types'
import React from "react";
import { Card, CardBody, CardFooter, CardHeader, CardTitle, FormGroup, Input, Container, Row, Col } from "reactstrap";
import { toggleStandardLoader } from 'store/functions/system/system';

import _template_docs_sent from '_functions/template_docs_sent';

import keys from 'keys';


class SignConfirmAccess extends React.Component {

    state = {
        access_code: '',
        access_error: false,
        has_access_code: true,
        signed_error: true,
    }

    //on keypress of enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			document.getElementById("confirm-access-button").click();
		}
	}


    onConfirm = async (access_code) => {

        const code = access_code ? access_code : this.state.access_code

        toggleStandardLoader(true)

        this.setState({access_error: false})

        const confirmed = await _template_docs_sent.validateAccessCode({
            template_doc_sent: this.props.foundDocument._id,
            access_code: code ? code.toString() : code,
        })
                
        if(confirmed.data) {

            if(confirmed.data.signed_on) {
                this.setState({signed_error: true})
            } else {
                this.props.onConfirmed(code, confirmed.data.party_index, confirmed.data.contact)
            }
        } else {
            this.setState({access_error: true})
        }

        toggleStandardLoader(false)

    }

    focusInput = () => {

        const input = document.getElementById('confirm-access-input');
        if(input) input.focus();

    }

    componentDidMount = () => {
        if(this.props.accessCode) {
            this.onConfirm(this.props.accessCode)
        } else {
            this.setState({has_access_code: false})
        }
        this.focusInput();
    }

    render() {

        const { access_error, has_access_code, signed_error } = this.state;

        if(has_access_code) {

            if(access_error) {
                return <div className="alert alert-danger text-center"><i className="fas fa-exclamation-triangle mr-2 " /> You do not have access to this page.</div>
            }

            if(signed_error) {
                return <div className="alert alert-success text-center"><i className="far fa-check-circle mr-2 " /> This document has already been signed.</div>
            }

            return <div />

        }

        return (

            <>

                <Card className="bg-dark" style={{marginBottom: 5}}>
                    <CardBody>
                        <Row>
                            <Col xs={6} className="align-self-center">
                                <img src={keys.LOGO} alt="Logo" style={{maxWidth: '100%', width: 200}} />
                            </Col>
                            <Col xs={6} className="text-right align-self-center">
                                <h2 className="text-white  mb-0">Documents</h2>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Container className="pt-6">
                    <Card className="bg-dark" style={{maxWidth: 600, margin: 'auto'}}>

                        <CardHeader className="bg-dark border-bottom">
                            <CardTitle className="text-white text-capitalize mb-0">Welcome!   <span className="text-white text-sm font-weight-bold">Your security is our top concern.</span></CardTitle>
                        </CardHeader>

                        <CardBody className="border-bottom">

                          
                            <p className="text-white text-sm">Before we can get started  <b>eSigning</b> your document confirm your identity by entering your access code below.</p>
                            <p className="text-white text-sm"><i className="fas fa-exclamation mr-2 text-warning" />Note that once you start the document signing process do not refresh your screen. To keep your information secure you must sign and confirm your document in 1 session.</p>

                            <FormGroup>
                                <label className="form-control-label text-white">Access Code *</label>
                                <Input
                                    id="confirm-access-input"
                                    onKeyDown={this._handleKeyDown}
                                    type="text"
                                    value={this.state.access_code}
                                    onChange={(e) => this.setState({access_code: e.target.value})}
                                />
                            </FormGroup>

                            {access_error ? (
                                <p className="text-sm font-weight-bold text-danger mb-0"><i className="fas fa-exclamation-triangle " /> Invalid Access Code Provided</p>
                            ) : null}

                        </CardBody>

                        <CardFooter className="bg-dark text-right">
                            <button onClick={this.onConfirm} className={"btn btn-success"} id="confirm-access-button" >
                                <i className="fas fa-check mr-2" />
                                Confirm Details
                            </button>
                        </CardFooter>

                    </Card>
                </Container>

            </>


        );
    }
}

SignConfirmAccess.propTypes = {
    onConfirmed: PropTypes.func.isRequired,
    error: PropTypes.string,

}

export default SignConfirmAccess
