/*
Documentation

this page holds all the routes for the dashboard layout

*/


import AppointmentsBook      from '../pages/appointments/Book';
// import AppointmentsConfirm   from '../pages/appointments/Confirm';
import AppointmentsViewEmail from '../pages/appointments/ViewEmail';


import Sign           from '../pages/sign';
import FormsFinish           from '../pages/forms/Finish';

import OptOut                from '../pages/opt_out/index.js';

export default () => {

    return[


        /***********************
        Appointments
        ************************/
        {
            path: "/appointments/view_email/:notification_id",
            component: AppointmentsViewEmail,
        },
        // {
        //     path: "/appointments/confirm/:_id",
        //     component: AppointmentsConfirm,
        // },
        {
            path: "/appointments/book/:_id",
            component: AppointmentsBook,
        },

        /***********************
        // TEMPLATE DOCS
        // ************************/
        {
            path: "/signatures/pdf/sign/:template_doc_sent_id",
            component: Sign,
        },

        /***********************
        Signatures
        ************************/
        {
            path: "/forms/finish/:form",
            component: FormsFinish,
        },

        /***********************
        Opt Out
        ************************/
        {
            path: "/opt_out/:identifier",
            component: OptOut,
        },
      

    ]

}
