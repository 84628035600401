/*
Documentation

this is the wrapping component for all auth routes
if a user is already known and logged in it will redirect
them off to their destination

if not destination is know we will redirect to /dashboard

*/

import React, { Component } from "react";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import routes from "../layout/routes";
import { getUrlParameter } from 'utils/urls';

import stackLogger from 'database/stackLogger';

class Auth extends Component {

    state = {}

    componentDidMount() {

        //if we are not on the logout page
        if(window.location.pathname !== '/auth/logout') {
            const redirect = getUrlParameter('redirect')

            const viewing_user = this.props.viewing_user;

            if(viewing_user && viewing_user.logged_in) {

                if(redirect) {

                    this.setState({shouldRedirect: redirect})

                } else {

                    this.setState({shouldRedirect: '/dashboard'})

                }

            }
        }

        document.body.classList.add("bg-default");

    }

    componentWillUnmount() {

        document.body.classList.remove("bg-default");

    }

    componentDidUpdate(e) {

        if (e.history.pathname !== e.location.pathname) {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;

            if(this.refs.mainContent) {
                this.refs.mainContent.scrollTop = 0;
            }
        }

    }
    getRoutes = routes => {
        return routes.map((prop, key) => {

            return (
                <Route
                    path={`/auth${prop.path}`}
                    component={prop.component}
                    key={key}
                />
            );

        });
    };


    getRedirectUrl = () => {
        var getUrl = window.location;
        var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];

        return baseUrl
    }


    componentDidCatch = (e) => {

        console.log(e);
        stackLogger(e);

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    render() {

        if(this.state.shouldRedirect) {
            return <Redirect to={this.state.shouldRedirect} />
        }

        return (

        <>
            <div className="main-conten" ref="mainContent">

                {/* <Navbar className="navbar-horizontal navbar-main navbar py-3 bg-primary " expand="lg" id="navbar-main">
                    <Container>

                        <NavbarBrand to="/" tag={Link}>
                            <img alt="..."  src={keys.LOGO} />
                        </NavbarBrand>

                        <button
                            aria-controls="navbar-collapse"
                            aria-expanded={false}
                            aria-label="Toggle navigation"
                            className="navbar-toggler"
                            data-target="#navbar-collapse"
                            data-toggle="collapse"
                            id="navbar-collapse"
                            type="button">
                            <span className="navbar-toggler-icon"/>
                        </button>

                        <UncontrolledCollapse
                            className="navbar-custom-collapse"
                            navbar
                            toggler="#navbar-collapse"
                        >
                            <div className="navbar-collapse-header">
                                <Row>

                                    <Col className="collapse-brand" xs="6">
                                        <Link to="/admin/dashboard">
                                            <img alt="..." src={keys.LOGO}/>
                                        </Link>
                                    </Col>

                                    <Col className="collapse-close" xs="6">
                                        <button
                                            aria-controls="navbar-collapse"
                                            aria-expanded={false}
                                            aria-label="Toggle navigation"
                                            className="navbar-toggler"
                                            data-target="#navbar-collapse"
                                            data-toggle="collapse"
                                            id="navbar-collapse"
                                            type="button">
                                            <span/>
                                            <span/>
                                        </button>
                                    </Col>

                                </Row>
                            </div>

                        </UncontrolledCollapse>
                    </Container>

                </Navbar>
                 */}
                <Switch>{this.getRoutes(routes)}</Switch>

            </div>
        </>
        );
    }
}

const mapStateToProps = state => {
    return {
      viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(Auth);

