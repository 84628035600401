import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';

const Logo = require('assets/img/brand/FairmaxLogoNew.png')
const People = require('assets/img/misc/People.jpg')

class OptOutEmail extends Component {


    state = {

    };

    render() {

        return (

            <div className="actions-wrapper">

                <Row>

                    <div className="col-auto col-left z-depth-5">

                        <Container className="py-4">
                            <img src={Logo} style={{maxWidth: 250}} alt="Fairmax Law" className=" w-100" />
                        </Container>

                        <hr className="my-3" />

                        <Container>
                            {this.props.children}
                        </Container>

                    </div>

                    <Col  className="bg-primary px-0 col-right">
                        {/* <div style={{minHeight: '100vh', backgroundSize: 'cover', backgroundPosition: 'bottom', backgroundImage: 'url("https://fairmaxlaw.com/static/media/SpecializeInPeopleImg.9648ad1e.png")'}} /> */}
                        <div style={{minHeight: '100vh', backgroundSize: 'cover', backgroundPosition: 'bottom', backgroundImage: `url("${People}")`}} />

                    </Col>

                </Row>

            </div>

        )

    }

}

export default OptOutEmail;
