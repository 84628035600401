import { architeckCall } from 'database';

export default (user_id) => {
    return new Promise (async resolve => {

        const viewing_user = await architeckCall({
            method: 'get',
            url: `/api/v1/auth/contact/viewing_user`
        })


        resolve(viewing_user)
    
    })
}