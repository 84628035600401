/*
Documentation

this is the wrapping component for the dashboard routes
it should check to see if a user is logged in
if not kick them out the /auth/login

it will also load any data needed for the dashboard to work
before rendering its routes

*/

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "./routes";

import stackLogger from 'database/stackLogger';

class Actions extends React.Component {
    state = {
        logged_in: true,
        loaded: false,
        notifications: []
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {

        return (
            <Route
                exact path={`/actions${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })


    componentDidCatch = (e) => {

        console.log(e);
        stackLogger(e);

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    render() {

        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }

        return (
            <div className="actions-layout">

                <Switch>
                    {this.getRoutes(routes)}
                    <Redirect from="*" to="/actions" />
                </Switch>

            </div>

        );
    }
}


export default Actions
