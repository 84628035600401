import React from "react";
import { Container, Progress } from 'reactstrap';

class SignSignatureLoading extends React.Component {

    state = {
        progress: 1
    }

   
    render() {

        const { progress } = this.props;

        return (

            <>

                <div className="text-center pt-3">
                    <h3 className="text-white font-weight-bold  mb-0 display-4 text-uppercase">Loading document</h3>
                    <p className="text-muted text-sm mb-0"><i className="fas fa-exclamation-triangle mr-2 text-danger " /> Do not refresh your page</p>
                </div>
                
                <Container>
                    <div className="progress-wrapper">
                        <Progress max="100" value={progress} color={progress < 40 ? 'warning' :  'success'} />
                    </div>
                </Container>

            </>

        );
    }
}

export default SignSignatureLoading
