/*
Documentation

this page renders the master calendar

blue underline means date is future and it has events /tasks
red underline means a task is overdue on this day or time

*/


import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle'
import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, Container, CardTitle, CardHeader } from "reactstrap";
import _contacts from '_functions/contacts';

import moment from 'moment'

import EventModal from './components/EventModal'

class CalendarCalendar extends React.Component {

    state = {
        eventToShow: null,
        showModal: false,
        appointments: null,
    }

    showEvent = (values) => {

        if(values.event) {
            this.setState({eventToShow: values.event, showModal: true})
        }

    }

    updateEvent = (event) => {

        const appointments = [...this.state.appointments]
        appointments[appointments.findIndex(el => el._id === event._id)] = event

        this.setState({appointments})

    }

    componentDidMount = async () => {

        const appointments = await _contacts.queryCalendar(this.props.viewing_user._id)

        if(appointments.success) {

            let data = appointments.data

            // data = data.filter(d => d.notify_client === 'yes')
            data.sort((a, b) => a.date < b.date ? -1 : 1)

            this.setState({appointments: data})

        }

    }


    render() {

        const { eventToShow, showModal, appointments } = this.state

        if(!appointments) return <div className="py-6"><Circle /></div>

        return (

            <Container>

            <Helmet>
                <title>Calendar</title>
                <meta name="description" content="Calendar" />
            </Helmet>

            <DashHeaderOpen
                icon="fas fa-home"
                icon_link="/dashboard"
                title={<span>Calendar</span>} breadcrumb_1="View"
            />

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">My Calendar</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">

                        <thead>
                            <tr>
                                <th>Date</th>
                                <th className="text-right">Appointment</th>
                            </tr>
                        </thead>

                        <tbody>
                        {appointments && appointments.length ? appointments.map(app => (
                            <tr key={app._id}>
                                <td style={{whiteSpace: 'pre'}}>
                                    <div>{moment.unix(app.date).format('MMM Do, YYYY')}</div>
                                    <div className="font-weight-bold">{moment.unix(app.date).format('h:mm')} - {moment.unix(app.date_end).format(' h:mm A')}</div>
                                </td>
                                <td style={{whiteSpace: 'pre'}} className="text-capitalize text-right">{app.name}</td>
                            </tr>
                        )) : (
                            <tr>
                                <td>You have no scheduled appointments coming up.</td>
                                <td className="text-right"></td>
                            </tr>
                        )}
                        </tbody>

                    </table>
                </div>

            </Card>

            <EventModal
                eventToShow={eventToShow}
                showModal={showModal}
                toggle={() => this.setState({eventToShow: null, showModal: false})}
                updateEvent={(event) => this.updateEvent(event)}
            />

        </Container>


        );
    }
}



const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(CalendarCalendar);
