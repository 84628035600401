/*
Documentation

uploads a file to s3

@props

zone_id: a unique id on the page. can be any standard html id
url: the url to post to, we add in the keys here so just add the suffix after the hostname
onUpload: a function that takes the result as a param on successful file upload

note there is no on error function as our backend should catch all errors

*/

import Dropzone from "dropzone";
import keys from 'keys';
import React from "react";
import PropTypes from 'prop-types'
import { toggleAlert } from 'store/functions/system/system';
import Dots from 'components/markup/loading/Dots'
import Circle from 'components/markup/loading/Circle'
import { Row } from 'reactstrap';
import { setDocumentLoading } from 'store/functions/system/system'
import heic2any from 'heic2any'
import ImgPreview from './ImgPreview'
import FileNameInput from './FileNameInput';
import { connect } from 'react-redux';

const unsupportedTypes = [
    'image/jp2'
]

const MAX_UPLOADS = 50

class DragAndDrop  extends React.Component {

    state = {
        has_files: [],
        files_to_upload: null,
        files_uploaded: 0,
        loading: false,
        startingUpload: false,
        loadingHeic: 0,
        finishTokens: {},
    }

    // show the file error for 2 seconds then remove it
    showFileError = () => {
        this.setState({fileTypeError: true}, () => {
            setTimeout(() => { this.setState({fileTypeError: false}) }, 2000)
        })
    }

    // remove file from state and show the message that it was rejected
    showRejectedError = (file) => {
        this.removeFile(file)

        this.setState({rejectedType: file.type}, () => {
            setTimeout(() => this.setState({rejectedType: false}), 3000)
        })
    }

    // show the maximum number of files that can be uploaded at one time
    showMaxFileError = () => {
        this.setState({maxFileError: true}, () => {
            setTimeout(() => { this.setState({maxFileError: false}) }, 3000)
        })
    }

    // remove a single file
    removeFile = (file) => this.state.Dropzone.removeFile(file);

    // remove files, set loading to false, and reset the state to show no files are uploaded
    // or currently trying to upload
    removeFiles = () => {
        // remove the files from showing
        this.setState({ has_files: [], loading: false, files_to_upload: 0, files_uploaded: 0, startingUpload: false })
        //remove the files from the dropzone
        this.state.Dropzone.removeAllFiles();
    }

    // document_uploaded
    onDocumentUploaded = (data) => {

        const documents_loading = [...this.props.documents_loading];
        const foundUpload = documents_loading.find(doc => doc.finishToken === data.data.finishToken)

        // document was uploaded by someone else or another drop zone. do nothing
        if(!foundUpload) return
        // document was already processed, do nothing, may happen if 2 drag and drops are on the same page
        if(foundUpload.status === 'success') return;

        const { file } = foundUpload;

        if(data.data.success && file ) {
            setDocumentLoading({ fileId: file.upload.uuid, status: 'success', timestamp: Math.floor(new Date() / 1000) })
            if(foundUpload.onUpload) foundUpload.onUpload(file, data.data)
        } else {
            this.onError(foundUpload, data.data.message, file)
        }

        const finishedDoc = documents_loading.find(el => el.file.upload.uuid === file.upload.uuid)
        if(finishedDoc) finishedDoc.status = 'success';

        const totalUploaded =   documents_loading.filter(d => d.batchNumber === foundUpload.batchNumber && d.status !== 'starting')
        const totalSavedBatch = documents_loading.filter(d => d.batchNumber === foundUpload.batchNumber)

        if(totalUploaded.length >= totalSavedBatch.length && foundUpload.onFinalUpload) {
            this.setState({isUploading: false})
            foundUpload.onFinalUpload();
        }
    
    }

    onError = (foundUpload, err, file) => {

        toggleAlert(true, 'danger', 'Something went wrong uploading a document.', 4000)

        this.state.Dropzone.removeAllFiles();

        if(foundUpload.onError) foundUpload.onError(err)
        setDocumentLoading({ fileId: file.upload.uuid, status: 'error', timestamp: Math.floor(new Date() / 1000) })

    }

    //when this is fired any files in the dropzone will be uploaded
    onUpload = () => {

        const { onUploadStarted } = this.props;
        const {Dropzone, files_to_upload} = this.state

        if(Dropzone.files && Dropzone.files.length) {

            const { shouldShowLoader } = this.props

            const validated = this.validateFiles()
            if(!validated) return;

            const to_upload = files_to_upload ? files_to_upload : Dropzone.files.length;
            const loading = shouldShowLoader ? true : false

            this.setState({files_to_upload: to_upload, loading, fileTypeError: false})

            if(Dropzone.files.find(f => f.status === 'error')) {
                this.showFileError()
                return this.removeFiles();
            }

            this.setState({startingUpload: true, isUploading: true, batchNumber: Math.floor(new Date() / 1)}, () => {

                if(onUploadStarted) onUploadStarted()

                //send off the files
                Dropzone.files.forEach(async (f, index) => {
                    Dropzone.enqueueFile(Dropzone.files[index]);
                })

            })
        
        } else {
            this.state.Dropzone.removeAllFiles();
        }

    }

    onRemoveFileForUpload = (file, index) => {

        const { has_files } = this.state;
        const { onFileRemoved } = this.props;

        //remove from the dropzone
        this.removeFile(file)

        //remove from preview
        if(has_files && has_files.length) {

            let currentFiles = [...has_files]
            currentFiles = currentFiles.filter((f, i) => i !== index)

            this.setState({has_files: currentFiles})

            if(onFileRemoved) onFileRemoved(currentFiles)
        }

    }

    onFriendlyNameChange = (e, file, index) => {

        const value = e.target.value;

        let has_files = [...this.state.has_files]
        has_files[index].friendly_name = value;

        this.setState({has_files})

    }

    validateFiles = () => {

        const { defaultValue } = this.props;

        let has_files = [...this.state.has_files]
        let errors = 0;
        let fileNames = []

        has_files.forEach((file, index) => {

            let originalName = file.name;
            let friendly_name = file.friendly_name;

            // assume valid
            has_files[index].friendly_nameState = null

            // if not friendly name set invalid
            if(!friendly_name) {

                if(defaultValue) {
                    has_files[index].friendly_name = defaultValue
                    friendly_name = defaultValue
                } else {

                    has_files[index].friendly_nameState = 'File Name Required';
                    return errors++;

                }

            }

            if(friendly_name.includes('.')) {
                has_files[index].friendly_nameState = 'File Name May Not Contain a Period';
                return errors++;
            }

            const splitFile = originalName.split('.')
            const extension = splitFile[splitFile.length - 1]

            const finalName = friendly_name.trim().replace(/ /g, '_') + '.' + extension
            // has_files[index].friendly_name = finalName

            if(fileNames.includes(finalName)) {
                has_files[index].friendly_nameState = 'File Name May Not Be The Same As Another File Being Uploaded';
                return errors++;
            } else {
                fileNames.push(finalName)
            }

        })

        // only change the file name if we don't have errors and are about to send
        if(!errors) {

            has_files.forEach((file, index) => {
                has_files[index].friendly_name = fileNames[index]
            })

        }

        this.setState({has_files});
        return !errors

    }

    onFileAdded = (file) => {

        const { defaultValue, uploadOne, onFileAdded } = this.props;

        if(!defaultValue && file.name) {
            file.friendly_name = file.name.split('.')[0]
        }

        let has_files

        if(uploadOne) {

            const totalFiles = this.state.Dropzone.files;
            if(totalFiles && totalFiles.length > 1 && uploadOne) return this.removeFile(file)

            has_files = [file]
            this.setState({has_files})

        } else {

            has_files = [...this.state.has_files]
            if(has_files.length + 1 > MAX_UPLOADS) return this.showMaxFileError()
            has_files.push(file)

            this.setState({has_files}, () => {

                // focus input when files are dragged in if possible
                setTimeout(() => {
                    const firstInput = document.getElementById(`drop-zone-name-${this.props.zone_id}-0`)
                    if(firstInput) firstInput.focus()
                }, 300)

            })

        }

        if(onFileAdded) onFileAdded(has_files)

    }

    setDropzoneListeners = () => {

        const { Dropzone } = this.state

        Dropzone.on("maxfilesexceeded", (file, xhr, formData) => {
            this.showMaxFileError(file)
        })

        Dropzone.on("sending", (file, xhr, formData) => {

            // Will any extra data given as props along with the file as POST data.
            const extraData = this.props.extraData

            if(extraData) {
                Object.keys(extraData).forEach(key => { formData.append(key, extraData[key]); })
            }

            const has_files = this.state.has_files;
            const found_file = has_files.find(f => f.upload.uuid === file.upload.uuid)

            let name = 'unknown document' + this.state.mountedTimestamp

            // add a timestamp to the end of the documents trying to be uploaded
            if(found_file && found_file.friendly_name) {

                let friendly_name
                const nameString = found_file.friendly_name.split('.')

                nameString.forEach((s, i) => {

                    if((i + 1) !== nameString.length) {
                        const value = s + this.state.mountedTimestamp + '.'
                        friendly_name = friendly_name ? 
                            friendly_name +  value : 
                            value
                    } else {
                        friendly_name =  friendly_name + nameString[nameString.length - 1]
                    }

                })

                name = friendly_name

            }

            formData.append('friendly_name', name);

        });

        //when a file is successfully uploaded
        Dropzone.on('success', (file, responseText) => {

            // potential functions to call from props
            const { onUpload, onError, onFinalUpload } = this.props;
            // store how many files to upload in the batch and how many have already been uploaded
            let {files_to_upload, files_uploaded, batchNumber} = this.state
            
            // if we have an onUploadStart function passed in run it
            // increment the number of files that have been uploaded
            files_uploaded++

            const isLastUpload = files_uploaded === files_to_upload;

            // if the number of files to upload matches the total number of uploads
            // we are good to stop loading and reset state to 0
            // if(this._isMounted) {
                this.removeFiles()
            // }

            let finishToken;

            // non chunking uploads
            if(responseText)  {
                // if we do not have success return the error
                if(!responseText.success) return this.onError(responseText, file)
                // our finish token in on the response text object;
                finishToken = responseText.data.finishToken;
               
            // chunking uploads
            } else {

                // finish token is help in the xhr, try to get it and if not return an error
                try {
                    responseText = JSON.parse(file.xhr.responseText)
                    finishToken = responseText.data.finishToken
                } catch(e) {
                    return this.onError({success: false, message: e}, file)
                }

            }

            if(!finishToken) this.onError({success: false, message: [`Finish token not found on the upload`]}, file)

            setDocumentLoading({ 
                file,
                responseText,
                finishToken   : finishToken,
                fileName      : file.friendly_name,
                fileId        : file.upload.uuid,
                status        : 'starting',
                size          : (file.size / 1000000).toFixed(2) + 'MB',
                timestamp     : Math.floor(new Date() / 1000),
                onUpload      : onUpload ? onUpload : null,
                onError       : onError ? onError : null,
                onFinalUpload : onFinalUpload ? onFinalUpload : null,
                batchNumber   : batchNumber,
                batchTotal    : files_to_upload,
            })

        })

        //when file is added add it to preview
        Dropzone.on("addedfile", async (file) => {
            // reject unsupported files types
            if(unsupportedTypes.includes(file.type) ) return this.showRejectedError(file);
            // render svg's as no preview
            if(file.type.match(/image.*/) && file.type !== 'image/svg+xml') return

            this.onFileAdded(file)
        })

        Dropzone.on('thumbnail', (file) => {

            // don't let unsupported types in
            if(unsupportedTypes.includes(file.type) ) return this.showRejectedError(file);

            // don't render svg's as thumbnails
            if(file.type === 'image/svg+xml') return

            if (file.type === 'image/heic') {

                this.setState({loadingHeic: this.state.loadingHeic + 1})

                return heic2any({ blob: file, toType: "image/jpeg" }).then(resultBlob => {

                    resultBlob.lastModifiedDate = file.lastModifiedDate;
                    resultBlob.name = file.name + ".jpg";
                    resultBlob.name = resultBlob.name.replace('.heic', '');

                    // add converted file to upload
                    Dropzone.handleFiles([resultBlob]);
                    // remove origin heic file for upload
                    Dropzone.removeFile(file);

                    this.setState({loadingHeic: this.state.loadingHeic - 1})

                }).catch((e) => {

                    // remove origin heic file for upload
                    Dropzone.removeFile(file);

                });

            }

            this.onFileAdded(file)

        })

        Dropzone.on("error", async (file, message) => {
            if(this.props.onError) this.props.onError(file, message)
        });

    }

    componentWillReceiveProps = (nextProps) => {

        if(nextProps.shouldFireUploads === true) {
            this.onUpload();
            if(this.props.onParentUpload) this.props.onParentUpload(this.state.Dropzone.files)
        }

    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }


    componentDidMount = async () => {

        this._isMounted = true

        //set dropzone as the state so it can be accessed late when we want to upload the file
        this.setState({
            mountedTimestamp:       '_' + Math.floor(new Date() / 1),
            Dropzone: new Dropzone(document.getElementById(this.props.zone_id), {
            headers                 : { method: 'post', authorization: `Bearer ${keys.SYSTEM_API_KEY}` },
            url                     : keys.API_URL + this.props.url,
            maxFiles                : MAX_UPLOADS + 1,
            autoQueue               : false,
            parallelUploads         : 100,
            timeout                 : 1000 * 180,
            withCredentials         : true,
            thumbnailWidth          : null,
            thumbnailHeight         : null,
            resizeMethod            : 'contain',
            chunking                : true,
            chunkSize               : 5242880, // 5 mb exactly
            parallelChunkUploads    : true,
            resizeQuality           : 1.0,
            resizeWidth             : this.props.resizePixels ? this.props.resizePixels : 2000,
            resizeHeight            : this.props.resizePixels ? this.props.resizePixels : 2000,
            previewsContainer       : document.getElementsByClassName( "dz-preview-single" + this.props.zone_id )[0],
            previewTemplate         : document.getElementsByClassName("dz-preview-single" + this.props.zone_id)[0].innerHTML,
            acceptedFiles           : this.props.acceptedFiles ? this.props.acceptedFiles : null,
            chunksUploaded          : (file, done) => { done() }
        })}, this.setDropzoneListeners)

        document.getElementsByClassName("dz-preview-single" + this.props.zone_id)[0].innerHTML = "";
    }

    render() {

        const { zone_id, defaultValue, shouldShowButton } = this.props
        const { fileTypeError, loading, has_files, loadingHeic, rejectedType, maxFileError, startingUpload } = this.state
        
        return (

            <div className="arch-media-library">

                <div className="dropzone dropzone-single mb-3" id={zone_id}>
                    <div className="fallback">
                        <div className="custom-file">
                            <input className="custom-file-input" id="projectCoverUploads" type="file"/>
                            <label className="custom-file-label" htmlFor="projectCoverUploads"> Choose file </label>
                        </div>
                    </div>
                    <div className={"dz-preview dz-preview-single" + zone_id}>
                        <div className="dz-preview-cover">
                        <img alt="..." className="dz-preview-img" data-dz-thumbnail=""/>
                        </div>
                    </div>
                </div>

                {maxFileError ? <div className="alert alert-danger mt-4">You may not upload more than {MAX_UPLOADS} files at a time.</div> : null}
                {fileTypeError ? <div className="alert alert-danger mt-4">One or more files was not of the correct type.</div> : null}
                {rejectedType ? (
                    <p className="text-sm font-weight-bold mt-4 mb-2">
                        <i className="fas fa-exclamation mr-2 text-danger" />
                        The file type "{rejectedType}" is not supported.
                        {has_files.length ? <hr /> : null}
                    </p>
                ): null}

                {((has_files && has_files.length) || loadingHeic) && !this.props.hidePreview ? (
                    <>

                        <h3>Preview Upload(s)</h3>

                        <div style={{maxHeight: 500, overflow: 'auto'}}>
                            {!loading ? has_files.map((i, index) => (
                                <div key={i.upload.uuid}  className="p-3 mb-3 rounded border bg-secondary">

                                    <FileNameInput 
                                        file={i}
                                        index={index} 
                                        zone_id={zone_id}
                                        friendly_nameState={i.friendly_nameState}
                                        defaultValue={defaultValue}
                                        onRemoveFileForUpload={this.onRemoveFileForUpload}
                                        onFriendlyNameChange={this.onFriendlyNameChange}
                                    />

                                    <Row>
                                        <div className="align-self-center col">
                                            <p className="mb--2 text-sm"><b className="text-dark" style={styles.details}>File:</b> {i.name.length > 30 ? i.name.slice(0, 30) + '...' : i.name}</p>
                                            <p className="mb--2 text-sm"><b className="text-dark" style={styles.details}>Type:</b> {i.type}</p>
                                            <p className="mb-0 text-sm"><b  className="text-dark" style={styles.details}>Size:</b> {(i.size / 1000000).toFixed(2)}MB</p>
                                        </div>
                                        <div className="align-self-center col-auto" style={{width: 150}}>
                                            <ImgPreview file={i} />
                                        </div>
                                    </Row>

                                </div>
                            )) : null}
                        </div>

                        {!loading && loadingHeic !== 0 ? (
                            <div className="p-3 mb-3 border rounded border bg-warning text-white">

                                <span>Loading Your Files<Dots /></span>

                                <p className="mb--2 text-sm"><b className="text-white" style={styles.details}>File:</b>-</p>
                                <p className="mb--2 text-sm"><b className="text-white" style={styles.details}>Type:</b>-</p>
                                <p className="mb-0 text-sm"><b  className="text-white" style={styles.details}>Size:</b>-</p>

                            </div>
                        ) : null}

                        <hr className="my-2" />

                        {shouldShowButton === false || !has_files.length ? null : (
                            <button disabled={startingUpload} className="btn btn-block btn-success" onClick={() => this.onUpload()} >
                                {startingUpload ? <span>Starting Upload <Dots /></span> : loading ? 'Uploading Files' : 
                                    this.props.btnText ? this.props.btnText : 
                                    has_files.length > 1 ? `Upload (${has_files.length}) files` : 
                                    `Upload (1) File`
                                }
                            </button>
                        )}

                        {loading && ( <div className="mt-3"> <Circle width={20} /> </div> )}

                    </>
                ): null}

            </div>

        );
    }
}

const styles = {

    details: {
        display: 'inline-block',
        width: 50,
        fontWeight: 'bold'
    }

}

DragAndDrop.propTypes = {
    //if true we are showing a selectable version of the DragAndDrop
    zone_id             : PropTypes.string.isRequired,
    url                 : PropTypes.string.isRequired,
    acceptedFiles       : PropTypes.string,
    uploadOne           : PropTypes.bool,

    onUploadStarted     : PropTypes.func,
    onUpload            : PropTypes.func,
    onError             : PropTypes.func,
    onFileAdded         : PropTypes.func,
    onFileRemoved       : PropTypes.func,
    onParentUpload      : PropTypes.func,
    extraData           : PropTypes.object,
    shouldShowButton    : PropTypes.bool,
    hidePreview         : PropTypes.bool,
    shouldShowLoader    : PropTypes.bool,
    btnText             : PropTypes.string,

    resizePixels        : PropTypes.number,
};

const mapStateToProps = state => {
    return {
        documents_loading: state.system.documents_loading
    };
};

export default connect(mapStateToProps, '')(DragAndDrop);
