/*
Documentation

compile and export all reducers for this system

*/

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import system from './reducers/system/system';
import auth from './reducers/auth/auth';
import device from './reducers/device/device';

//tell redux to match the following properties to each reducer
const rootReducer = combineReducers({
    auth,
    system,
    device,
})

const store = createStore(rootReducer, applyMiddleware(thunk));;
export default store
