import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Input, FormGroup } from 'reactstrap';
import moment from 'moment';

class SignModalFinishField extends React.Component {

    state = {
        name: {}
    }

    onInputChange = (e) => {

        const markerIndex = this.props.markerIndex
        const answer = e.target.value

        this.props.onChangeAnswer(markerIndex, answer)

    }

    componentWillReceiveProps = (nextProps) => {

        const wasOpened = !this.state.showModalFinishField && nextProps.showModalFinishField;
        const indexChanged = this.state.markerIndex !== nextProps.markerIndex;

        if(wasOpened || indexChanged) {
            setTimeout(() => {
                const el = document.getElementById('update-document-field')
                if(el) el.focus() 
            }, 200)
        }

    }

    render() {

        const { showModalFinishField, toggleModalFinishField, markers, markerIndex, moveForwards, moveBackwards } = this.props
        const { signature, initials, toggleModalAddSignature, toggleModalAddInitials } = this.props

        const marker = markers[markerIndex]

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModalFinishField}
                toggle={() => {}}
                size="lg"
            >
                {marker && (
                    <div>
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {marker.type === 'signature' ? 'Signature Field' : 
                                marker.type === 'initial' ? 'Initial Field' : 
                                marker.type === 'date' ? 'Date Field' : 
                                'Add Document Field'}
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={toggleModalFinishField}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            {marker.type === 'signature' ? (
                                <div>
                                    {signature ? (
                                        <div>
                                            <p className="text-sm border-bottom">
                                                Your signature will be filled based on the the one you have provided.{' '}
                                                <span className="text-underline cursor-pointer" onClick={toggleModalAddSignature}>Click here to change your signature.</span>
                                            </p>
                                            <img className="w-100" src={signature} alt="signature" />
                                        </div>
                                    ) : (
                                        <p className="text-sm mb-0 mt-3 text-center">
                                            You have not filled out your signature,{' '}<br />
                                            <button className="btn btn-success mb-4 mt-2" onClick={toggleModalAddSignature}>Add My Signature</button>
                                        </p>
                                    )}
                                </div>
                            ) : marker.type === 'initial' ? (
                                <div>
                                    {initials ? (
                                        <div>
                                            <p className="text-sm border-bottom">
                                                Your initials will be filled based on the the one you have provided.{' '}
                                                <span className="text-underline cursor-pointer" onClick={toggleModalAddInitials}>Click here to change your initials.</span>
                                            </p>
                                            <img className="w-100" src={initials} alt="signature" />
                                        </div>
                                    ) : (
                                        <p className="text-sm mb-0 mt-3 text-center">
                                            You have not filled out your initials,{' '}<br />
                                            <button className="btn btn-success mb-4 mt-2" onClick={toggleModalAddInitials}>Add My Initials</button>
                                        </p>
                                    )}
                                </div>
                            ) : marker.type === 'date' ? (
                                <p className="text-sm mb-0">
                                    This field will be filled with the date that you sign the document:{' '}
                                    <b className="font-weight-bold">{moment().format('MM/DD/YYYY')}</b>
                                </p>
                            ) : (
                                <FormGroup>
                                    <label className="form-control-label">{marker.name}</label>
                                    <Input 
                                        id="update-document-field"
                                        type="text"
                                        value={marker.answer || ''}
                                        onChange={this.onInputChange}
                                    />
                                </FormGroup>
                            )}
                        </div>

                        <div className="modal-footer">
                            <button onClick={moveBackwards} className="btn btn-outline-warning"><i className="fas fa-arrow-left mr-2 " /> Back</button>
                            <button onClick={moveForwards} className="btn btn-outline-success">Next</button>
                        </div>
                    </div>
                )}

            </Modal>

        );
    }
}

SignModalFinishField.propTypes = {
    showModalFinishField           : PropTypes.bool.isRequired,
    toggleModalFinishField         : PropTypes.func.isRequired,
}

export default SignModalFinishField
