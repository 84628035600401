/*
Documentation

this file shows a message that no account vaults exist for a contact

*/

import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom'

class HasNoVaults extends Component {

    state = {};

    render() {

        return (

            <Card className="bg-danger">

                <CardHeader className="bg-transparent">
                    <CardTitle className="text-white mb-0">Payment Alert</CardTitle>
                </CardHeader>

                <CardBody>
                    <p className="text-sm text-white">You do not have any payment methods on file.</p>
                    <p className="text-sm mb-0">
                        <Link className="text-white text-underline" to="/dashboard/billing/methods">
                            Click Here To Add One
                        </Link>
                    </p>
                </CardBody>

            </Card>

        )

    }

}

export default HasNoVaults;