import LOGO from 'assets/img/brand/FairmaxLogoLight.png'


let keys = {
    COMPANY: 'Fairmax Law',
    LOGO
};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === 'clients.myfairmax.com') {

    console.log('p')

    keys = {
        ...keys,
        API_URL:                'https://api.myfairmax.com',
        SYSTEM_API_KEY:         'architeck-3876143876',
        ZEAMSTER_ORIGIN:        'https://api.fortispay.com',
        CLIENT_URL:             'https://clients.myfairmax.com',
    }

//using staging keys
} else if(HOSTNAME === 'staging.clients.myfairmax.com') {

    console.log('s')

    keys = {
        ...keys,
        API_URL:            'https://staging.api.myfairmax.com',
        SYSTEM_API_KEY:     'architeck-4874517265',
        ZEAMSTER_ORIGIN:    'https://api.sandbox.zeamster.com',
        CLIENT_URL:         'https://staging.clients.myfairmax.com',
    }

//using development keys
} else {

    console.log('d')

    // wrapped in try catch as this will fail in staging and production
    // since this file does not exist in version control. Only used for dev purposes
    try {
       
        const devKeys = require('./keysDev')

        keys = {
            ...keys,
            ...devKeys
        }

   } catch(e) {}

}

export default keys
