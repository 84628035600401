/*
Documentation

This is just about the same file as ./Forgot.js
The difference is what the text displays to a user

*/

import classnames from "classnames";
import owasp from 'owasp-password-strength-test';
import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row, CardFooter, CardHeader, CardTitle } from "reactstrap";
import { Helmet } from 'react-helmet'
import keys from 'keys';

import Circle from 'components/markup/loading/Circle';
import { getUrlParameter } from 'utils/urls';
import renderName from 'utils/renderName';

import _contacts from '_functions/contacts';

import _auth from '_functions/auth';
import { setViewingUser } from 'store/functions/auth/auth';



class Register extends React.Component {

	state = {
        loaded: false,
        error: false,
        success: null,
        contact: null,
        unauthorized: false
    };

    //on enter simulate the form being submitted for better UI
	_handleKeyDown = (e, type) => {
		if (e.key === 'Enter') {
            document.getElementById("showPasswordUpdateButton").click();
		}
	}

    getName = () => {
        const { contact } = this.state;
        if(contact.given_name || contact.family_name) {
            return <span className="text-capitalize">Hi {renderName(contact)}!</span>
        }

        return 'Welcome To Fairmax Law';

    }


    onPasswordSet = async () => {

        this.setState({error: null})

        if(this.state.password_confirm !== this.state.password) {

            this.setState({error: 'Passwords do not match'})
            return;
        }

        //update the users password
        const updatePassword =  await _auth.password.update(this.state.contact._id, this.state.password, this.state.password_confirm)

        if(updatePassword.success) {

            //validate the users password
            const user = await _auth.password.validate(this.state.contact.email, this.state.password)

            if(user.success) {

                await setViewingUser();
                const redirect = getUrlParameter('redirect')
                this.setState({shouldRedirect: redirect ? redirect : '/dashboard'})

            } else {

                this.setState({error: user.message});

            }

        } else {
            this.setState({error: updatePassword.message})
        }

    }

    onPasswordChange = (e, stateName) => {

        let pt = this.state.passwordTest

        if(stateName === 'password') {
            pt = owasp.test(e.target.value)
        }

        let newState = Object.assign({}, {
            ...this.state,
            passwordTest: pt
        });
        newState[stateName] = e.target.value;

        if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
        } else {
            newState[stateName + "State"] = "valid";
        }

        this.setState({ ...newState });

    };

    componentDidMount = async () => {

        const access_code = getUrlParameter('access_code');
        const secure = getUrlParameter('secure');

        const foundContact = await _auth.password.getSignup({access_code, secure});

        console.log(foundContact)

        if(foundContact.data) {

            if(foundContact.data === 'already created') {
                this.setState({shouldRedirect: '/auth/login'})
            } else {
                this.setState({contact: foundContact.data, loaded: true})

            }

        } else {
            this.setState({unauthorized: true, loaded: true})
        }

    }

	render() {


        const pt = this.state.passwordTest;
        const { loaded, shouldRedirect, unauthorized } = this.state

        if(shouldRedirect) return <Redirect to={shouldRedirect} />
        if(!loaded) return <div className="py-6"><Circle /></div>

        if(unauthorized) return (
            <div className="my-6 p-3 z-depth-2 bg-white text-center" style={{maxWidth: '90%', width:400, margin: 'auto'}}>
                <p className="text-danger font-weight-bold">You do not have access to this page. </p>
                <p className="text-sm">If you wish to set up an account with Fairmax Law please contact us at:</p>
                <p className="text-sm mb-0 font-weight-bold"><a href="mailto:staff@fairmaxlaw.com">staff@fairmaxlaw.com</a></p>

            </div>
        )

		return (

		<>

            <Helmet>
                <title>Create Account</title>
                <meta name="description" content="Create Account" />
            </Helmet>

            <div className="header  py-7 py-lg-8 pt-lg-9">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">

                                <img style={{width: 400, maxWidth: '100%'}} alt="..."  src={keys.LOGO} />
                                <p className="text-white mt-3">Create Account</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

			<Container className="mt--9 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">
						<Card className="bg-white border-0 mb-0">

                            <CardHeader className="text-center">
                                <CardTitle className="mb-0">{this.getName()}</CardTitle>
                                <p className="text-sm mb-0">Please create a password for your account below.</p>
                            </CardHeader>

							<CardBody>

                          
                                <FormGroup className={classnames({     focused: this.state.focusedPassword })} >

                                    {this.state.passwordState === "invalid" && (
                                        <div className="text-danger font-italic"> <small> Required</small> </div>
                                    )}

                                    {pt && pt.optionalTestErrors && pt.optionalTestErrors.length ? (
                                        <>

                                    { pt.optionalTestErrors.map((p, index) => {
                                            return <div key={index} className="text-danger font-italic"> <small> {p}</small> </div>
                                        })}
                                        </>

                                    ): null}

                                    <InputGroup className="input-group-merge input-group-alternative">

                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>

                                        <Input
                                            onKeyDown={(e) => this._handleKeyDown(e, 'showPasswordUpdate')}
                                            placeholder="Password"
                                            type="password"
                                            value={this.state.password || ''}
                                            onChange={e => this.onPasswordChange(e, "password") }
                                            onFocus={() => this.setState({ focusedPassword: true })}
                                            onBlur={() => this.setState({ focusedPassword: false })  }
                                        />

                                    </InputGroup>

                                </FormGroup>

                                    <FormGroup className={classnames({ focused: this.state.focusedPassword })} >

                                    {this.state.password_confirmState === "invalid" && (
                                        <div className="text-danger font-italic"> <small> Required</small> </div>
                                    )}


                                    <InputGroup className="input-group-merge input-group-alternative">

                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>

                                    <Input
                                        onKeyDown={(e) => this._handleKeyDown(e, 'showPasswordUpdate')}
                                        placeholder="Confirm Password"
                                        type="password"
                                        value={this.state.password_confirm || ''}
                                        onChange={e => this.onPasswordChange(e, "password_confirm") }
                                        onFocus={() => this.setState({ focusedPassword: true })}
                                        onBlur={() => this.setState({ focusedPassword: false })}
                                />

                                    </InputGroup>

                                </FormGroup>

							</CardBody>

                            <CardFooter className="text-center">
                                {this.state.error && <span className="text-danger small">{this.state.error}<br /></span>}
                                <Button id="showPasswordUpdateButton" onClick={this.onPasswordSet} color="success" type="button">
                                    Confirm Password
                                </Button>
                            </CardFooter>
						</Card>

					</Col>
				</Row>
			</Container>
		</>
		);
	}
}

export default Register

