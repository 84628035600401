import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col } from 'reactstrap';
import keys from 'keys';

class SignError extends Component {

    render() {

        return (

            <>


            <Card className="bg-dark" style={{marginBottom: 5}}>
                <CardBody>
                    <Row>
                        <Col xs={6} className="align-self-center">
                            <img src={keys.LOGO} alt="Logo" style={{maxWidth: '100%', width: 200}} />
                        </Col>
                        <Col xs={6} className="text-right align-self-center">
                            <h2 className="text-white  mb-0">Unavailable</h2>
                        </Col>
                    </Row>
                </CardBody>
            </Card>


            <Container className="archk-template-docs-container mt-4">
                <Card className="bg-danger" style={{maxWidth: 600, margin: 'auto'}}>

                    <CardHeader className="bg-danger">
                        <CardTitle className="mb-0 text-white"><i className="fas fa-exclamation-triangle mr-2" />Document Unavailable</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-white text-sm mb-0">The document you are looking for has already been signed or is unavailable at this time.</p>
                    </CardBody>

                </Card>
            </Container>

        </>

        )

    }

}

export default SignError;
