/*
Documentation

this page holds all the routes for the auth layout

*/

import Forgot from '../pages/Forgot';
import Register from '../pages/Register';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import QuickSignUp from '../pages/QuickSignUp';

export default [

    {
        path: "/forgot",
        component: Forgot,
    },
    {
        path: "/register",
        component: Register,
    },
    {
        path: "/sign-up",
        component: QuickSignUp,
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/logout",
        component: Logout,
    },
    {
        path: "",
        component: Login,
    },

]