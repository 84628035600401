import keys from 'keys';
import Axios from 'axios';
import store from 'store';
import StackTrace from "stacktrace-js";

const stackLogger = async (err) => {

    console.log(err)

    StackTrace.fromError(err).then(async stackError => {

        const payload = {
            url: window.location.href,
            file: '/src/database/js',
            code: 'REQUEST FAILURE',
            text: JSON.stringify(stackError)
        }
    
        const device = store.getState().device.info;
    
        payload.device = {
            type    : device.device,
            mobile  : device.isMobile,
            width   : device.currentScreenWidth,
            height  : device.currentScreenHeight,
        }
    
        try {
            const log = await Axios({
                method: 'post',
                url: keys.API_URL + '/api/v1/logs/create',
                headers: {
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                withCredentials: true,
                data: payload
            })
    
            if(!log.data.success && window.location.hostname === 'localhost') {
                console.log('log failed 1')
                console.log(log)
            }
    
    
        } catch(e) {
    
            if(window.location.hostname === 'localhost') {
                console.log(e)
            }
    
        }

    });

}

export default stackLogger;