import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Container, Card, CardBody, CardHeader, CardFooter, CardTitle, CardText, Row, Col } from "reactstrap";
import { Link } from 'react-router-dom'
import Circle from 'components/markup/loading/Circle';
import _cases from '_functions/cases';
import renderName from 'utils/renderName'

class Dashboard extends React.Component {

    state = {
        case: null
    }

    componentDidMount = async () => {

        const cases = await _cases.find(`?filter=contact__${this.props.viewing_user._id}|deleted__false{bool}`)
        this.setState({cases: cases.data})

    }

    render() {

        const _cases = this.state.cases

        return (
            <>

            <Container>

                <Helmet>
                    <title>Dashboard</title>
                    <meta name="description" content="Dashboard" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Dashboard</span>}
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Case Documents.</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <CardText className="text-sm"> Select a case below to view all documents needed.</CardText>
                    </CardBody>

                </Card>

                <hr />

                {!_cases ? <Circle /> : (
                    <Row>
                            {_cases && _cases.length ? _cases.map(c => (
                        <Col lg={4} key={c._id}>
                            <Link to={`/dashboard/cases/view/${c._id}/documents`}>
                                <Card className="text-center cursor-hover">

                                    <CardHeader>
                                        <CardTitle className="mb-0">{c.type}</CardTitle>
                                    </CardHeader>

                                    <CardBody>
                                        <h2 className="mb-0 text-capitalize">{c.name}</h2>
                                        <p className={c.finished_on ? "text-sm text-success mb-0" : "text-sm text-warning mb-0"}>
                                            <b>{c.finished_on ? 'Finished' : 'In Progress'}</b>
                                        </p>
                                    </CardBody>

                                    <CardFooter>
                                        <CardTitle className="mb-0 text-capitalize">
                                            Attorney:{' '}
                                            <b>{c.lead_attorney ? renderName(c.lead_attorney) : 'None Assigned'}</b>
                                        </CardTitle>
                                    </CardFooter>

                                </Card>
                            </Link>
                        </Col>
                    )) : (
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <h2 className="mb-0">You account does not have any cases associated with it</h2>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                    </Row>
                )}

            </Container>

            </>
        );
    }
}



const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(Dashboard);
