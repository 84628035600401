import { architeckCall } from 'database';

export default (user_id) => {
    return new Promise (async resolve => {
    
        const login = await architeckCall({
            method:'post',
            url: '/api/v1/auth/contact/logout',
        })

        resolve(login)
    
    })
}