/*
Documentation

This is the navbar for the dashboard layout

*/

import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar, NavItem, NavLink, } from "reactstrap";
import keys from 'keys'


class NavbarBottom extends React.Component {

	state = {
        showMobileNav: false
    }

    toggleMobileNav = () => {

        const showMobileNav = this.state.showMobileNav

        if(showMobileNav) {
            document.body.classList.remove('noScroll')
        } else {
            document.body.classList.add('noScroll')
        }

        this.setState({showMobileNav: !showMobileNav})
    }

    closeMobileNav = () => {
        document.body.classList.remove('noScroll')
        this.setState({showMobileNav: false})
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.closeMobileNav)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.closeMobileNav)
    }

	render() {

        const { showMobileNav } = this.state

        const location = this.props.location


    	return (

			<>

				<Navbar className="navbar-expand border-bottom navbar navbar-mobile d-block d-md-none "  >
					<Container>

						<Nav className="align-items-center mx-auto" navbar>
                            <NavLink
                                tag={Link}
                                to="/dashboard/home"
                                className="pl-0 "
                            >
								<NavItem tag="span" className="text-white" style={{cursor: 'pointer', paddingLeft: 0}}>
                                    <img src={keys.LOGO} className="w-50 " alt={keys.COMPANY_NAME} />
								</NavItem>
							</NavLink>
						</Nav>

						<Nav className="align-items-center ml-auto ml-md-0" navbar>
                            <NavLink
                                tag={Link}
                                to="/defaultevent"
                                className="pr-0 "
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleMobileNav()
                                }}
                            >
								<NavItem tag="span" className="text-white" style={{cursor: 'pointer', paddingLeft: 0, fontSize: '1.2rem'}}>
                                    <i className="fas fa-bars" />
								</NavItem>
							</NavLink>
						</Nav>

					</Container>
				</Navbar>

                {showMobileNav ? (
                    <div className="navbar-mobile-content d-block d-md-none ">

                        <ul>

                            <li className="divider">
                                <a href="/none" onClick={(e) => e.preventDefault()}>
                                    My Fairmax Law
                                </a>
                            </li>

                            <li className={location.includes('/dashboard/home') ? 'active' : ''} >
                                <Link to="/dashboard/home" onClick={() => this.closeMobileNav()}>
                                    <i className="fas fa-home" /> Dashboard
                                </Link>
                            </li>

                            <li className={location.includes('/dashboard/document_select') ? 'active' : ''} >
                                <Link to="/dashboard/document_select" onClick={() => this.closeMobileNav()}>
                                    <i className="fas fa-file" /> Documents
                                </Link>
                            </li>

                            <li className={location.includes('/dashboard/calendar/') ? 'active' : ''} >
                                <Link to="/dashboard/calendar/calendar" onClick={() => this.closeMobileNav()}>
                                    <i className="fas fa-calendar" /> Calendar
                                </Link>
                            </li>

                            <li className="divider">
                                <a href="/none" onClick={(e) => e.preventDefault()}>
                                    Payments
                                </a>
                            </li>

                            <li className={location.includes('/dashboard/billing/methods') ? 'active' : ''} >
                                <Link to="/dashboard/billing/methods" onClick={() => this.closeMobileNav()}>
                                    <i className="fas fa-money-check-alt mr-2" /> My Cards
                                </Link>
                            </li>
                            <li className={location.includes('/dashboard/billing/payments') ? 'active' : ''} >
                                <Link to="/dashboard/billing/payments" onClick={() => this.closeMobileNav()}>
                                <i className="fas fa-dollar-sign mr-2" /> My Payments
                                </Link>
                            </li>
                            <li className={location.includes('/dashboard/billing/invoices') ? 'active' : ''} >
                                <Link to="/dashboard/billing/invoices" onClick={() => this.closeMobileNav()}>
                                <i className="fas fa-dollar-sign mr-2" /> My Invoices
                                </Link>
                            </li>


                            <li className="divider">
                                <a href="/none" onClick={(e) => e.preventDefault()}>
                                    My Account
                                </a>
                            </li>

                            <li className={location.includes('/dashboard/settings') ? 'active' : ''} >
                                <Link to="/dashboard/settings" onClick={() => this.closeMobileNav()}>
                                    <i className="fas fa-cogs" /> Settings
                                </Link>
                            </li>
                            <li>
                                <Link to="/auth/logout" onClick={() => this.closeMobileNav()}>
                                <i className="ni ni-user-run" /> Logout
                                </Link>
                            </li>

                        </ul>

                    </div>
                ) : null}

			</>

    	);
  	}
}

const mapStateToProps = state => {
  	return {
        viewing_user: state.auth.viewing_user,

  	};
};

export default connect(mapStateToProps, '')(NavbarBottom);
