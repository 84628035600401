import keys from 'keys';
import React from "react";

import { toggleAlertBS } from 'store/functions/system/system';

import _template_docs_sent from '_functions/template_docs_sent'

import { Container } from 'reactstrap';
import renderUpload from '../_functions/renderUpload';
import { getMarkerPositionOnPage, getMarkerName } from '../_functions/utils'

import Loading from './Loading'
import Progress from './Progress'

import ModalFinishField from '../Modals/FinishField'
import ModalAddSignature from '../Modals/AddSignature'
import ModalAddInitials from '../Modals/AddInitials'
import ModalHelp from '../Modals/Help'

import { Row, Col, Card, CardBody } from 'reactstrap';

const getStartedPrompt = `Let's sign your document! Take as much time as you'd like to look things over and when ` +
`you're ready click the green button in the bottom right corner of your screen to get started.`

class SignSignature extends React.Component {

    state = {
        markers: [],
        internalMarkers: [],
        loaded: false,
        pdfData: null,
        docName: false,
        markerIndex: -1,
        lastIndex: 0,
        showModalFinishField: false,
        showModalAddSignature: false,
        showModalAddInitials: false,
        showHelp: false,
        signature: null,
        initials: null,
        signatureWidth: 0,
        initialsWidth: 0,
        progress: {},
        canFinish: false,
        renderedPages: <div />,
        showMarkers: false,
        hasShownReady: false,
        images: []
    }

    windowWidth = window.innerWidth;

    onPreview = () => {

        const { signature, initials, markers } = this.state
        this.props.onPreview({ signature_image_64 : signature, initial_image_64 : initials, markers})

    }

    getProgress = () => {

        const { markers, signature, initials, canFinish, showModalFinishField } = this.state

        let finished = 0;
        let total = 0

        markers.forEach(marker => {

            if(marker.type !== 'date') total++;

            if(marker.type === 'text' && marker.answer && marker.answer.trim())  finished++;
            if(marker.type === 'signature' && signature) finished++;
            if(marker.type === 'initial' && initials)    finished++;

        })

        this.setState({progress: { total, finished }})

        if(total === finished && !canFinish) {
            this.setState({canFinish: true, hasShownReady: true});

            // only show this once
            if(!this.state.hasShownReady) {
                toggleAlertBS('info', `You're ready to sign this document. Click the button "Review And Sign" on the bottom of your screen when you are ready to proceed!`);
                if(showModalFinishField) this.toggleModalFinishField()
            }
           
        } else {
            this.setState({canFinish: false});
        }

    }

    onChangeAnswer = (index, answer) => {
        const markers = [...this.state.markers];
        markers[index].answer = answer;

        this.setState({markers});
        this.getProgress();
    }

    setSignature = (signature, signatureWidth) => {
        this.setState({signature, signatureWidth}, this.getProgress);
    }

    setInitials = (initials, initialsWidth) => {
        this.setState({initials, initialsWidth}, this.getProgress);
    }

    unFocusLastMarker = () => {
        const { lastIndex }  = this.state
        const lastSelectedMarker = document.querySelector(`[data-marker-index='${lastIndex}']`);

        lastSelectedMarker.classList.remove('is-focused')
    }

    focusMarker = (markerIndex) => {
        this.unFocusLastMarker();

        const { markers } = this.state

        const selectedMarker = document.querySelector(`[data-marker-index='${markerIndex}']`);

        if(selectedMarker) {
            selectedMarker.classList.add('is-focused')
            this.setState({lastIndex: markerIndex})
        }

        window.scrollTo({top: getMarkerPositionOnPage(markers[markerIndex]).top - 0, left: 0})
    
    }

    moveBackwards = () => {
        let {markers, markerIndex} = Object.assign({}, this.state);
        markerIndex = markerIndex - 1;

        if(markerIndex < 0) markerIndex = markers.length - 1;

        this.focusMarker(markerIndex)
        this.setState({markerIndex})
    }

    moveForwards = () => {
        let {markers, markerIndex} = Object.assign({}, this.state);
        markerIndex = markerIndex + 1;

        if(markerIndex >= markers.length) markerIndex = 0;

        this.focusMarker(markerIndex)
        this.setState({markerIndex})
    }

    toggleModalAddSignature = () => this.setState({showModalAddSignature: !this.state.showModalAddSignature})
    toggleModalAddInitials = () => this.setState({showModalAddInitials: !this.state.showModalAddInitials})
    toggleModalHelp = () => this.setState({showHelp: !this.state.showHelp})

    toggleModalFinishField = (markerIndex) => {
        const { showModalFinishField } = this.state

        if(showModalFinishField) {
            this.unFocusLastMarker()
        } else {
            this.focusMarker(markerIndex)
        }

        const newIndex = markerIndex !== undefined ? markerIndex : this.state.markerIndex

        this.setState({showModalFinishField: !showModalFinishField, markerIndex: newIndex})
    }

    getImgWidth = (positionStyles, imgWidth) => {

        let finalWidth  = 'auto';

        const markerWidth = positionStyles.width;
        const markerHeight = positionStyles.height;

        finalWidth = markerWidth;

        const widthRatio = imgWidth / finalWidth;
        const heightAfterRatio = markerHeight * widthRatio;

        if(heightAfterRatio < markerHeight) {
            finalWidth = finalWidth / heightAfterRatio
        } else {
            finalWidth = finalWidth / (heightAfterRatio / markerHeight)
        }

        return finalWidth * 2;

    }

    // render markers that have been answered internally and should be hard coded as values
    // on the document to be signed
    createInternalTextMarkers = () => {

        const { loaded, internalMarkers } = this.state

        if(loaded) return internalMarkers.map((marker, i) => {

            if(marker.type !== 'text') return <div />;

            try {

                const positionStyles = getMarkerPositionOnPage (marker);
                const style = { position : 'absolute', ...positionStyles };

                return  (
                    <span key={i} style={style} >
                        <div 
                            style={{
                                width: marker.right - marker.left, 
                                overflow: 'hidden', 
                                fontSize: positionStyles.fontSize, 
                                color: 'black',
                                position: 'relative',
                                bottom: -3
                            }} 
                            className="marker-name font-weight-bold"
                        >
                            {marker.answer ? marker.answer : marker.name}
                        </div>
                    </span>
                )
            } catch(e) {
                console.log(e)
                return <div />
            }

        })

    }

    createMarkers = () => {

        const { loaded, markers, markerIndex, signature, initials, signatureWidth, initialsWidth } = this.state

        if(loaded) return markers.map((marker, i) => {

            try {

                let finalWidth = 'auto';
                let finalHeight = 'auto';

                const positionStyles = getMarkerPositionOnPage (marker);

                const style = { position : 'absolute', ...positionStyles };
                const key = marker.top.toString() + marker.left.toString();

                let classNames = marker.answer || marker.type === 'date' || (marker.type === 'signature' && signature) || (marker.type === 'initial' && initials) ? 
                    'pdf-marker pdf-marker-filled z-depth-2' : 
                    'pdf-marker z-depth-2';

                if(marker.type === 'date') classNames += ' pdf-marker-date'

                if(i === markerIndex) classNames += ' is-focused'

                if(marker.type === 'signature') finalWidth = this.getImgWidth(positionStyles, signatureWidth)
                if(marker.type === 'initial') finalWidth = this.getImgWidth(positionStyles, initialsWidth)

                return  (
                    <span onClick={() => this.toggleModalFinishField(i)}  key={key} data-marker-index={i} className={classNames} style={style} >
                        <div style={{width: marker.right - marker.left, overflow: 'hidden', fontSize: positionStyles.fontSize}} className="marker-name">
                            {marker.type === 'signature' && signature ? (
                                <img style={{width: finalWidth, height: finalHeight}} src={signature} alt="signature" />
                            ) : marker.type === 'initial' && initials ? (
                                <img style={{width: finalWidth, height: finalHeight}} src={initials} alt="initials" />
                            ) : (
                                getMarkerName(marker)
                            )}
                        </div>
                    </span>
                )
            } catch(e) {
                console.log(e)
                return <div />
            }

        })

    }

    onImgLoaded = (img, page) => {
        const images = [...this.state.images]
        images[page] = img;
        this.setState({images})
    }


    setImagesToMarkup = () => {

        const markup = [];

        this.state.images.forEach((image, i) => {
            markup.push((
                <div key={i}>
                    <p className="text-white text-sm text-right mb-0">Page {i + 1} / 46</p>
                    <div id={`pdf-canvas-page-${i + 1}-wrapper`} data-pdf-page-wrapper="true" style={{position: 'relative', width: '100%'}}>
                        <div className="page-overlay" />
                        <img id={`pdf-canvas-page-${i + 1}`} className="z-depth-3 mb-3 rounded" data-page={i + 1} src={image} alt={`page ${i + 1}`} style={{width: '100%'}} />
                    </div>
                </div>
            ))
        })

        this.setState({renderedPages: markup,}, () => {
            setTimeout(() => {
                this.setState({showMarkers: true})
            }, 1000)
        })

    }

    onResize = () => {

        this.setState({showMarkers: false}, () => {
            this.setState({showMarkers: true})
        })

    }


    componentWillUnmount = () => {
        window.removeEventListener('resize', this.onResize)
    }

    componentDidMount = async () => {

        const doc = await _template_docs_sent.findById(this.props.template_doc_sent_id);

        document.body.classList.add('noScroll')
        window.addEventListener('resize', this.onResize)

        if(doc.data) {

            const docName = doc.data.name;
            const pdfData = doc.data.document;

            const markers = doc.data.markers.filter(m => m.party === this.props.party_index)
            const internalMarkers = doc.data.markers.filter(m => this.props.party_index !== 0 && m.party === 0 && m.type === 'text') 
            markers.sort((a, b) => a.top < b.top ? -1 : 1)
            markers.sort((a, b) => a.pageNumber < b.pageNumber ? -1 : 1)

            if(doc.data.initial_pages === 'true' && markers.length && !markers.some(m => m.type === 'initial')) {

                const marker = markers[0];

                markers.unshift({
                    bottom: marker.pageHeight - 20,
                    left: marker.pageWidth - 80,
                    pageHeight: marker.pageHeight,
                    pageNumber: 1,
                    pageWidth: marker.pageWidth,
                    party: this.props.party_index,
                    required: true,
                    right: marker.pageWidth - 20,
                    top: marker.pageHeight - 40,
                    type: "initial",
                })

            }

            this.setState({ docName, markers, pdfData, internalMarkers })

            renderUpload(doc.data, this.onImgLoaded, (pdfDoc) => {
                this.setState({loaded: true, pdfDoc})
                document.body.classList.remove('noScroll')

                toggleAlertBS('info', getStartedPrompt)
                this.setImagesToMarkup()

            })

            this.getProgress();

        }

    }

    render() {

        const { loaded, markers, showModalAddSignature, showModalAddInitials, showModalFinishField, markerIndex, signature } = this.state;
        const { initials, progress, renderedPages, showMarkers, images, showHelp } = this.state;

        const { contact, template_doc_sent_id } = this.props;

        return (

            <>

                <Card className="bg-dark" style={{marginBottom: 5}}>
                    <CardBody>
                        {loaded ? (
                            <Row>
                                <Col xs={5}>
                                    <img src={keys.LOGO} alt="Logo" style={{maxWidth: '100%', width: 200}} />
                                </Col>
                                <Col xs={7} className="text-right">
                                    {loaded && <i style={{position: 'relative', top: 4}} onClick={this.toggleModalHelp} className="fas fa-info-circle cursor-pointer text-info-original " />}
                                </Col>
                            </Row>
                        ) : (
                            <div className="text-center">
                                  <img src={keys.LOGO} alt="Logo" style={{maxWidth: '100%', width: 200}} />
                            </div>
                        )}
                    </CardBody>
                </Card>
                
                <Container className="archk-template-docs-container pb-6">
                    <div className="archk-template-docs"  >

                        <div className="archk-template-docs-main">
                            <div style={{height: 'auto',  maxWidth: '100%',  margin: 'auto'}} >

                                {!loaded && <Loading progress={(images.length / 46) * 100} /> }

                                <div id="canvas-wrapper" className="position-relative" style={{opacity: loaded ? 1 : 0}}>
                                    <div id="pdf-canvas" className="position-relative">
                                        {loaded ? renderedPages : <></>}
                                        {showMarkers ? this.createMarkers() : null}
                                        {showMarkers ? this.createInternalTextMarkers() : null}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <Progress 
                            loaded={loaded} 
                            progress={progress} 
                            onPreview={this.onPreview} 
                            toggleModalFinishField={() => this.toggleModalFinishField(markerIndex >= 0 ? markerIndex : 0)}
                        />

                    </div>
                </Container>

                <div id="canvas-anchor" style={{display: 'none'}} />

                <ModalHelp 
                    showHelp={showHelp}
                    toggleModalHelp={this.toggleModalHelp}
                />

                <ModalFinishField
                    moveForwards={this.moveForwards}
                    moveBackwards={this.moveBackwards}
                    markerIndex={markerIndex}
                    markers={markers}
                    onChangeAnswer={this.onChangeAnswer}
                    showModalFinishField={showModalFinishField}
                    signature={signature}
                    initials={initials}
                    toggleModalFinishField={() => this.toggleModalFinishField()}
                    toggleModalAddSignature={this.toggleModalAddSignature}
                    toggleModalAddInitials={this.toggleModalAddInitials}
                />

                {showModalAddSignature ? (
                    <ModalAddSignature 
                        showModalAddSignature={showModalAddSignature}
                        toggleModalAddSignature={this.toggleModalAddSignature}
                        setSignature={this.setSignature}
                        signature={signature}
                        contact={contact}
                        template_doc_sent_id={template_doc_sent_id}
                    />
                ) : null}

                {showModalAddInitials ? (
                    <ModalAddInitials 
                        showModalAddInitials={showModalAddInitials}
                        toggleModalAddInitials={this.toggleModalAddInitials}
                        setInitials={this.setInitials}
                        initials={initials}
                        contact={contact}
                        template_doc_sent_id={template_doc_sent_id}
                    />
                ) : null}

            </>

        );
    }
}

export default SignSignature
