/*
Documentation

This page shows all of the systems contacts

*/

import React from "react";
import { connect } from 'react-redux';
import { Modal, Row, Col } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

import moment from 'moment';
import _appointments from '_functions/appointments';

const TextRow = (props) => (
    <Row>
        <Col md={4}>
            <p className="mb-0 text-sm font-weight-bold">{props.title}</p>
        </Col>
        <Col md={8}>
            <p className="mb-0 text-sm">{props.value}</p>
        </Col>
    </Row>
);


class CalendarEventModal extends React.Component {

    state = {
        filter: null
    }


    fireAlert = (error, message) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success={error ? false : true}
                    danger={!error ? false : true}
                    title={error ? 'Error' : 'Success'}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    {message}
                </ReactBSAlert>
            )
        });
    };


    hideAlert = () => {

        this.setState({ alert: null })
        this.props.toggle()
    };

    onConfirmAppointment = async () => {

        const eventToShow = this.props.eventToShow

        const confirmed = await _appointments.update(eventToShow._id, {
            ...eventToShow,
            confirmed: true
        })

        if(confirmed.success) {

            this.props.updateEvent(confirmed.data)
            this.fireAlert(false, 'Event confirmed!')

        } else {

            this.fireAlert(false, 'Something went wrong confirming this event.')

        }

    }

    render() {

        const { showModal, toggle, eventToShow } = this.props

        if(!eventToShow) return <div></div>

        return (

            <>

            {this.state.alert}


            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={() => toggle()}
                size="md"
            >


                <div className="modal-header">

                    <h5 className="modal-title" id="exampleModalLabel">View Appointment </h5>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggle()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>

                </div>

                <div className="modal-body">

                    <TextRow
                        title="Name"
                        value={eventToShow.name}
                    />
                    <TextRow
                        title="Description"
                        value={eventToShow.description}
                    />
                    <TextRow
                        title="Location"
                        value={eventToShow.location}
                    />

                    <hr className="my-2" />

                    <TextRow
                        title="Date"
                        value={moment.unix(eventToShow.date).format('MMMM Do, YYY')}
                    />
                    <TextRow
                        title="Start"
                        value={moment.unix(eventToShow.date).format('h:mm A')}
                    />
                    <TextRow
                        title="End"
                        value={moment.unix(eventToShow.date_end).format('h:mm A')}
                    />

                    <hr className="my-2" />

                    <TextRow
                        title="Case"
                        value={eventToShow.case ? eventToShow.case.name : 'Case Not Found'}
                    />

                    <TextRow
                        title="Confirmed"
                        value={eventToShow.confirmed ? <b className="text-success">Yes</b> : <b className="text-danger">No</b>}
                    />

                </div>

                {!eventToShow.confirmed ? (
                    <div className="modal-footer text-right">
                        <button onClick={this.onConfirmAppointment} className="btn btn-success">Confirm Event</button>
                    </div>
                ) : null}

            </Modal>

            </>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user
    };
};

export default connect(mapStateToProps, '')(CalendarEventModal);

