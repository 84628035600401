import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, CardHeader, Container } from "reactstrap";
import { formatMoney } from 'utils/currency';
import _payments from '_functions/payments';
import Circle from 'components/markup/loading/Circle';


class BillingPayments extends React.Component {

    state = {
        payments: null
    }

    columns = [
        {
            dataField: "created_at",
            text: "Date",
            formatter: (cell, row) => (
                <span>
                    {moment.unix(row.created_at).format('MMMM Do, YYYY')} <br />
                    {moment.unix(row.created_at).format('h:mm A')}

                </span>
            ),
        },
        {
            dataField: "amount",
            text: "Amount",
            formatter: (cell, row) => {
                return formatMoney(row.transaction_amount)
            }
        },
        {
            dataField: "type",
            text: "Type",
            formatter: (cell, row) => (
                <span className="text-capitalize">{row.type}</span>
            )
        },
        {
            dataField: "account_type",
            text: "Method",
            formatter: (cell, row) => (
               row.account_type ? (
                <span>
                    <b>{_payments.cards.getName(row.account_type)}{' '}</b> <br />
                    {row.first_six.slice(0,1)}...{row.last_four}
                </span>
               ) : 'Cash'
            )
        },
        {
            dataField: "invoice",
            text: "Invoice ID",

        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {

                let markup;

                if(row.status === 'succeeded') {

                    markup = <Badge style={{width: 130}} className="d-inline-block text-sm" color="success">Succeeded</Badge>

                } else if (row.status === 'failed') {

                    markup = <Badge style={{width: 130}} className="d-inline-block text-sm" color="danger">Failed</Badge>

                } else {

                    markup = <Badge style={{width: 130}} className="d-inline-block text-sm" color="warning">Refunded</Badge>

                }

                return (
                    <div className="text-right"> {markup} </div>
                )

            }
        },

    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: false,
        withFirstAndLast: false,
        sizePerPageRenderer: () => false
    });

    componentDidMount = async () => {

        const payments = await _payments.findByContact(this.props.viewing_user._id)

        if(payments.success) {
            this.setState({payments: payments.data.payments})
        }

    }

    render() {

        const payments = this.state.payments

        if(!payments) return <div className="py-6"><Circle /></div>

        return (
            <>

            <Container>

                <Helmet>
                    <title>Billing Overview</title>
                    <meta name="description" content="Billing Overview" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Billing</span>} breadcrumb_1="Overview"
                />


                <Card>

                    <CardHeader>
                        <h3 className="mb-0">My payments</h3>
                    </CardHeader>

                       { payments.length ? (
                            <ToolkitProvider
                                data={payments}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <div className="table-not-fixed table-responsive table-vertical-align">
                                        <BootstrapTable
                                            pagination={this.pagination}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        ) : (
                            <CardBody>
                                <Link to="/dashboard/contacts/create">
                                    There have been no payments created for your account.
                                </Link>
                            </CardBody>
                        )}

                </Card>

            </Container>

            </>
        );
    }
}



const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(BillingPayments);

