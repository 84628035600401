import React from "react";

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import _template_docs_sent from '_functions/template_docs_sent'
import _template_docs_sent_events from '_functions/template_docs_sent_events'
import { getUrlParameter } from 'utils/urls';

import Circle from 'components/markup/loading/Circle'

import Signature from './Signature'
import ConfirmAccess from './ConfirmAccess'
import Preview from './Preview'
import Download from './Download'
import Error from './Error'

class Sign extends React.Component {

    state = {
        originalLoad: false,
        preview: false,
        confirmed: false,
        signed: false,
        contact: ''
    }

    onConfirmed = (access_code, party_index, contact) => {
        this.setState({confirmed: true, access_code, party_index, contact})
        _template_docs_sent_events.create({
            template_docs_sent: this.props.match.params.template_doc_sent_id,
            contact,
            event: 'Opened Document'
        })
    }

    onSign = async () => {

        toggleStandardLoader(true)

        const preview = await _template_docs_sent.sign({
            template_doc_sent_id  : this.props.match.params.template_doc_sent_id,
            signature_image_64    : this.state.signature_image_64,
            initial_image_64      : this.state.initial_image_64,
            markers               : this.state.markers,
            access_code           : this.state.access_code,
            device              : {
                type    : navigator.userAgent,
                width   : window.innerWidth,
                height  : window.innerHeight,
            },
            time_to_sign : this.state.started ? Math.floor(new Date() / 1000) - this.state.started : 0,
        })
        
        toggleStandardLoader(false);

        if(preview.data) {
            _template_docs_sent_events.create({
                template_docs_sent: this.props.match.params.template_doc_sent_id,
                contact: this.state.contact,
                event: 'Signature Executed'
            })
    
            this.setState({signed: true, document_base_64: preview.data})
        }

    }

    onBackToSign = () => this.setState({preview: false})

    onPreview = async (data) => {

        toggleStandardLoader(true)

        const preview = await _template_docs_sent.sign({
            template_doc_sent_id  : this.props.match.params.template_doc_sent_id,
            preview               : true,
            signature_image_64    : data.signature_image_64,
            initial_image_64      : data.initial_image_64,
            markers               : data.markers,
            access_code           : this.state.access_code,
        })

        if(preview.data) {
            _template_docs_sent_events.create({
                template_docs_sent: this.props.match.params.template_doc_sent_id,
                contact: this.state.contact,
                event: 'Finalized Document With Fields And Signature'
            })
    
            this.setState({
                preview: true, 
                previewData: preview.data, 
                signature_image_64: data.signature_image_64, 
                initial_image_64: data.initial_image_64, 
                markers: data.markers,
                access_code           : this.state.access_code,
            })
        } else {
            toggleStandardLoader(false);
            toggleAlertBS('info', `Please make sure you have filled in all required fields.`)
        }

    }

    componentDidMount = async () => {
        
        document.body.style.background = '#333'

        const { template_doc_sent_id } = this.props.match.params

        const templateDocSent = await _template_docs_sent.findById(template_doc_sent_id, true);

        let error = false

        if(!templateDocSent.data) {
            error = `The document you are trying to sign has expired`
        }

        if(templateDocSent.data) {
            if(templateDocSent.data.status === 'signed' || templateDocSent.data.status === 'void') {
                error = `The document you are trying to sign is no longer available to be accessed.`
            }
        }

        const initialPages = templateDocSent.data.initial_pages;
        const docName = templateDocSent.data.name;
        const pdfData = templateDocSent.data.document;

        const markers = templateDocSent.data.markers.filter(m => m.party === this.state.party_index)

        if(error) {
            this.setState({originalLoad: true, error, loaded: true, docName, initialPages, pdfData, markers})
        } else {
            this.setState({originalLoad: true, started: Math.floor(new Date() / 1000), foundDocument: templateDocSent.data, initialPages, loaded: true, templateDocSent})
        }

    }

    render() {

        const { confirmed, foundDocument, previewData, preview, document_base_64, signed, error, originalLoad, docName, initialPages, pdfData, markers, party_index } = this.state;
        const { contact } = this.state;

        if(!originalLoad) return (
            <div className="pt-3"><Circle /></div>
        )

        // if we have an error OR there is no document found on the template_doc_sent
        if(error || (foundDocument && !foundDocument.document)) {
            return <Error />
        }

        if(signed) {
            return (
                <Download 
                    template_doc_sent_id={this.props.match.params.template_doc_sent_id} 
                    document_base_64={document_base_64}
                    contact={contact}

                />
            )
        }


        if(!confirmed) {
            return (
                <ConfirmAccess 
                    foundDocument={foundDocument}
                    onConfirmed={this.onConfirmed}
                    accessCode={getUrlParameter('token')}
                />
            )
        }

        if(!preview) {
            return (
                <Signature 
                    template_doc_sent_id={this.props.match.params.template_doc_sent_id} 
                    onPreview={this.onPreview}
                    docName={docName}
                    pdfData ={pdfData}
                    markers={markers}
                    party_index={party_index}
                    foundDocument={foundDocument}
                    contact={contact}
                />
            )
        }

      
        return (
            <Preview 
                template_doc_sent_id={this.props.match.params.template_doc_sent_id} 
                previewData={previewData}
                onSign={this.onSign}
                onBackToSign={this.onBackToSign}
                initialPages={initialPages}
                foundDocument={foundDocument}
                contact={contact}

            />
        )
       
    }
}

export default Sign
