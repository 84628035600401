import { architeckCall } from 'database';

export default (data) => {
    return new Promise (async resolve => {
    
        const call = await architeckCall({
            shouldNotShowAndLogError: true, 
            method:'post',
            url: '/api/v1/auth/contact/get_signup',
            data
        })

        resolve(call)
    
    })
}