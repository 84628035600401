import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import { formatMoney } from 'utils/currency';
import Circle from 'components/markup/loading/Circle';
import moment from 'moment';

class Invoices extends Component {

    state = {

    };

    render() {

        const invoices = this.props.invoices;

        return (

            <>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0 text-capitalize">All Invoices</CardTitle>
                </CardHeader>

                {invoices ? (
                    invoices.length ? (
                        <div className="table-responsive">
                            <table className="table">

                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Paid</th>
                                        <th>Total</th>
                                        <th className="text-right">Date Sent</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {invoices.map(i => (
                                        <tr key={i._id}>
                                            <td style={{whiteSpace: 'pre', width: 180}}>{i.name}</td>
                                            <td style={{whiteSpace: 'pre', width: 110}}>{formatMoney(i.total_paid)}</td>
                                            <td style={{whiteSpace: 'pre', width: 110}}>{formatMoney(i.total)}</td>
                                            <td style={{whiteSpace: 'pre'}} className="text-right">{ moment.unix(i.date).format('MM/DD/YYYY')}</td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                    ) : (
                        <CardBody>
                            <p className="text-sm mb-0 font-weight-bold">
                                There are no current invoices for this case.
                            </p>
                        </CardBody>
                    )

                ) : (
                    <Circle />
                )}

            </Card>

            </>

        )

    }

}


Invoices.propTypes = {
    invoices: PropTypes.array.isRequired,
}

export default Invoices;
