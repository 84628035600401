import apiWorker from '../apiWorker'

import cardsGetName from './cards/getName'

import contactsFindOrCreate from './contacts/findOrCreate'

export default {

    contacts: {
        findOrCreate: contactsFindOrCreate //
    },

    account_vaults: {
        delete: (account_vault_id, data) => apiWorker('delete', `/api/v1/billing/account_vaults/delete/${account_vault_id}`, data),
        updateDefaultVaults: (data) => apiWorker('patch', `/api/v1/billing/recurrings/updateDefaultVaults`, data),
    },

    // these will charge a card or fake it as a cash payment
    transactions: {
        card:       (account_vault_id, data)            => apiWorker('post', `/api/v1/billing/transactions/card/${account_vault_id}`, data, true),
    },

    findByCase:    (case_id)     => apiWorker('get', `/api/v1/billing/payments/case/${case_id}`),
    findByContact: (contact_id)  => apiWorker('get', `/api/v1/billing/payments/contact/${contact_id}`),

    createPayform: (contact_id)  => apiWorker('get', `/api/v1/billing/payform/create_url/${contact_id}`),

    cards: {
        getName: cardsGetName,
    },

    projections: {
        schedule: (case_id) => apiWorker('get', `/api/v1/billing/projections/schedule/case/${case_id}`),
    }

}
