/*
Documentation

This is the navbar for the dashboard layout

*/

import keys from 'keys';
import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Media, Nav, Navbar, NavItem, UncontrolledDropdown } from "reactstrap";


class NavbarTop extends React.Component {

	state = {
		showKnowledgeBase: false,
		showModalFindCase: false,
		showModalFindContact: false,
		nav1Columns: null,
		nav1NoPermissions: false
	}

	render() {

		const viewing_user = this.props.viewing_user

    	return (

			<>

            <Navbar style={{background: '#053A53'}} className="navbar-top navbar-expand border-bottom navbar"  >
				<Container>

					<Nav className="align-items-center d-none d-md-block" navbar>
						<NavItem className="mr-5">
							<Link to="/dashboard">
								<img alt={keys.COMPANY_NAME} className="navbar-brand-img" src={keys.LOGO} />
							</Link>
						</NavItem>

					</Nav>

					{/* ------------------------------------------------------------------ */}
					<Collapse navbar isOpen={true}>

						<Nav className="align-items-center ml-md-auto " navbar>
						</Nav>


						{/* ------------------------------------------------------------------ */}
						<Nav className="align-items-center ml-auto ml-md-0" navbar>

							<UncontrolledDropdown nav>

							<DropdownToggle className="nav-link pr-0" color="" tag="a">
								<Media className="align-items-center">


									<Media className="ml-2 d-none d-md-block">
										<span className="mb-0 text-sm font-weight-bold text-white text-capitalize">
											{viewing_user.given_name} {viewing_user.family_name}
											<i className="ml-1 fas fa-caret-down"></i>
										</span>
									</Media>

								</Media>
							</DropdownToggle>

							<DropdownMenu className="bg-primary" right>

								<DropdownItem className="noti-title" header tag="div">
									<img src={keys.LOGO} className="w-75 " alt="Fairmax" />
								</DropdownItem>

								<DropdownItem divider />


								<Link to="/dashboard/settings">
									<DropdownItem	>
									<i className="fas fa-cogs" />
										<span>Account Settings</span>
									</DropdownItem>
								</Link>

								<Link to="/auth/logout" >
									<DropdownItem>
										<i className="ni ni-user-run" />
										<span>Logout</span>
									</DropdownItem>
								</Link>

							</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>

					</Collapse>
				</Container>
			</Navbar>

			</>

    	);
  	}
}

const mapStateToProps = state => {
  	return {
    	viewing_user: state.auth.viewing_user,
  	};
};

export default connect(mapStateToProps, '')(NavbarTop);
