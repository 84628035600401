import Circle from 'components/markup/loading/Circle';
import moment from 'moment';
import React from "react";
import { Helmet } from 'react-helmet';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Col, Row } from "reactstrap";
import _appointments from '_functions/appointments';
import reactHTMLParser from 'react-html-parser'

import keys from 'keys';

class CalendarCalendar extends React.Component {

    state = {
        body: null,
        err: null
    }

    componentDidMount = async () => {

        const foundNotification = await _appointments.findNotification(this.props.match.params.notification_id, true)
        if(foundNotification.data) {
            this.setState({body: foundNotification.data})
        } else {
            this.setState({err: 'true'})
        }

    }


    render() {

        const { body, err } = this.state

        if(!body && !err) return <div className="py-6"><Circle /></div>

        if(err === 'true') return (
            <div className="p-3">
                <p className="text-center">The notification you are trying to view has expired.</p>
            </div>
        )

        return (

            <div>
                <Helmet>
                    <title>My Appointment</title>
                    <meta name="description" content="My Appointment" />
                </Helmet>

                <div style={{width: 800, maxWidth: '95%', margin: 'auto'}} className="py-2">

                    <Card>

                        <CardHeader className="bg-primary mx--3 mx-md-0 mt--3 py-4">
                            <div className="py-2">
                            <img style={{width: 200}} alt={keys.COMPANY_NAME} className="navbar-brand-img" src={keys.LOGO} />
                            </div>
                        </CardHeader>

                        <CardBody>
                            {reactHTMLParser(body)}
                        </CardBody>

                    </Card>

                </div>

            </div>


        );
    }
}

export default CalendarCalendar
