/*
Documentation

all dispatch actions should be declared in this file and used

this prevents mis-typing action names as it will through an error
by either the browser or IDE

*/


//system reducer
export const SYSTEM_SET_REDUCERS_FIRED    = 'SYSTEM_SET_REDUCERS_FIRED';
export const TOGGLE_SYSTEM_ALERT          = 'TOGGLE_SYSTEM_ALERT';
export const TOGGLE_SYSTEM_LOADER         = 'TOGGLE_SYSTEM_LOADER';
export const TOGGLE_STANDARD_LOADER       = 'TOGGLE_STANDARD_LOADER';
export const TOGGLE_SYSTEM_ALERT_BS       = 'TOGGLE_SYSTEM_ALERT_BS'

export const SET_DOCUMENT_LOADING       = 'SET_DOCUMENT_LOADING'
export const SET_DEVICE                 = 'SET_DEVICE'

//auth reducer
export const SET_VIEWING_USER             = 'SET_VIEWING_USER';



