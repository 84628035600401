import { architeckCall } from 'database';

export default (email, code) => {
    return new Promise (async resolve => {
    
        const validated = await architeckCall({
            shouldNotShowAndLogError: true, 
            method:'post',
            url: '/api/v1/auth/contact/security_code/validate',
            data: { email, code }
        })

        resolve(validated)
    
    })
}