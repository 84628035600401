/*
Documentation

this page handles log in for a user
on successful login we set a cookie architeck_uid
when we send off to see if the user is logged in this is
passed along as well to prevent cors cookie issues

*/

import keys from 'keys';
import React from "react";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import { setViewingUser } from 'store/functions/auth/auth';
import _auth from '_functions/auth';
import { getUrlParameter } from 'utils/urls';

class Login extends React.Component {

	state = {
        email: window.location.hostname === 'localhost' ? 'jmaher@fairmaxlaw.com' : '',
        password: window.location.hostname === 'localhost' ? 'Aaaa123*' : ''
    };

	//on keypress of enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {

			document.getElementById("loginButton").click();

		}
	}

	  onLogin = async () => {

        this.setState({error: null})

		const email = this.state.email;
		const password = this.state.password;

		if(!this.state.email) {
			this.setState({error: 'Invalid Email'});
			return;
		}

		if(!this.state.password) {
			this.setState({error: 'Invalid Password'});
			return;
		}

        //validate the users password
		const user = await _auth.password.validate(email, password)

        if(user.success) {

            await setViewingUser();
            const redirect = getUrlParameter('redirect')
            this.setState({shouldRedirect: redirect ? redirect : '/dashboard'})

        } else {

            this.setState({error: user.message});

        }

	}

	render() {


		if(this.state.shouldRedirect) {
			return <Redirect to={this.state.shouldRedirect} />
		}

		return (

		<>

			<Helmet>
				<title>Login</title>
				<meta name="description" content="Login" />
			</Helmet>

            <div className="header  py-7 py-lg-8 pt-lg-9">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">

                                <img style={{width: 400, maxWidth: '100%'}} alt="..."  src={keys.LOGO} />
                                {/* <p className="text-white mt-3">Log In Here</p> */}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

			<Container className="mt--9 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">
						<Card className="bg-white border-0 mb-0" style={{boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px'}}>


							<CardBody className="px-lg-5 py-lg-5">

                                <div className="text-center text-muted mb-4">
									<p className="font-weight-bold">Sign in To Your Account</p>
								</div>

								<Form role="form">
									<FormGroup>
                                        <label className="form-control-label">Email</label>
                                        <Input
                                            name="email"
                                            onKeyDown={this._handleKeyDown}
                                            placeholder="Email"
                                            type="email"
                                            value={this.state.email	 || ''}
                                            onChange={(e) => this.setState({email: e.target.value})}
                                            onFocus={() => this.setState({ focusedEmail: true })}
                                            onBlur={() => this.setState({ focusedEmail: false })}
                                        />
									</FormGroup>

									<FormGroup className="mt-4">
                                        <label className="form-control-label">Email</label>

                                        <Input
                                            name="password"
                                            onKeyDown={this._handleKeyDown}
                                            placeholder="Password"
                                            type="password"
                                            value={this.state.password || ''}
                                            onChange={(e) => this.setState({password: e.target.value})}
                                            onFocus={() => this.setState({ focusedPassword: true }) }
                                            onBlur={() => this.setState({ focusedPassword: false })}
                                        />
									</FormGroup>

									<div className="text-center mt-4 pt-1">
										{this.state.error && <span className="text-danger small">{this.state.error}<br /></span>}
										<Button id="loginButton" onClick={this.onLogin} className="my-4 btn-block" color="success" type="button">
											Sign in
										</Button>
									</div>

								</Form>
							</CardBody>
						</Card>

						<Row className="mt-3">

							<Col xs="6" className="text-left">
								<Link className="text-light" to="/auth/forgot" >
									<small>Forgot Password?</small>
								</Link>
							</Col>
							<Col xs="6" className="text-right">
								<Link className="text-light" to="/auth/register" >
									<small>Register</small>
								</Link>
							</Col>


						</Row>

					</Col>
				</Row>
			</Container>
		</>
		);
	}
}

export default Login

