import { architeckCall } from 'database';

export default (email, password) => {
    return new Promise (async resolve => {
    
        const validated = await architeckCall({
            shouldNotShowAndLogError: true, 
            method: 'post',
            url: '/api/v1/auth/contact/password_validate',
            data: {email, password, }
        })

        resolve(validated)
    
    })
}