import PropTypes from 'prop-types';
import React from 'react';
import { Modal, FormGroup } from 'reactstrap';

class SignModalConfirm extends React.Component {

    state = {
        confirmed1: false,
        confirmed2: false,
        confirmed3: false,
    }

    onSign = () => {
     
        const { confirmed1, confirmed2, confirmed3 } = this.state;

        this.setState({
            needConfirmed1: !confirmed1,
            needConfirmed2: !confirmed2,
            needConfirmed3: !confirmed3,
        })

        if(confirmed1 && confirmed2 && confirmed3) {
            this.props.onSign()
        }

    }

    render() {

        const { confirmed1, confirmed2, confirmed3, needConfirmed1, needConfirmed2, needConfirmed3 } = this.state
        const { showModalConfirm, toggleModalConfirm, toggleModalDisclosures, initialPages } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModalConfirm}
                toggle={toggleModalConfirm}
                size="md"
            >

                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Finish And Sign</h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModalConfirm}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <p className="text-sm">You are just about done. Confirm the fields below and click "Finish" to sign your document.</p>

                        {initialPages === 'true' ? (
                            <p className="text-sm">Please note by confirming and signing this document your initials will be placed on each page of the document.</p>
                        ) : null}

                        <FormGroup>
                            <div className={`custom-control custom-checkbox ${needConfirmed1 ? 'checkboxErr' : ''}`}>
                                <input
                                    className="custom-control-input"
                                    type="checkbox"
                                    id="confirmed1"
                                    value={confirmed1 || ''}
                                    checked={confirmed1}
                                    onChange={(e) => this.setState({confirmed1: !confirmed1})}
                                />
                                <label className="custom-control-label" htmlFor="confirmed1">
                                    I have confirmed the above document is correctly signed and filled out.
                                </label>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <div className={`custom-control custom-checkbox ${needConfirmed2 ? 'checkboxErr' : ''}`}>
                                <input
                                    className="custom-control-input"
                                    type="checkbox"
                                    id="confirmed2"
                                    value={confirmed2 || ''}
                                    checked={confirmed2}
                                    onChange={(e) => this.setState({confirmed2: !confirmed2})}
                                />
                                <label className="custom-control-label" htmlFor="confirmed2">
                                    I have confirmed that no part of my signature or initials are cut off and I give the consent required for this to be a legally binding document.
                                </label>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <div className={`custom-control custom-checkbox ${needConfirmed3 ? 'checkboxErr' : ''}`}>
                                <input
                                    className="custom-control-input"
                                    type="checkbox"
                                    id="confirmed3"
                                    value={confirmed3 || ''}
                                    checked={confirmed3}
                                    onChange={(e) => this.setState({confirmed3: !confirmed3})}
                                />
                                <label className="custom-control-label" htmlFor="confirmed3">
                                    I have read and accept the Consumer Disclosures regarding E-Signing documents. <span onClick={(e) =>{ e.preventDefault();  toggleModalDisclosures()}} className="text-underline text-dark font-weight-bold">Click Here To View</span>
                                </label>
                            </div>
                        </FormGroup>

                    </div>

                    {needConfirmed1 || needConfirmed2 || needConfirmed3 ? (
                        <div className="modal-body bg-secondary text-center">
                            <p className="text-warning font-weight-bold mb-0">Please check the boxes above before continuing.</p>
                        </div>
                    ) : null}
                    

                    <div className="modal-footer">
                        <button onClick={toggleModalConfirm} className="btn btn-outline-warning">Cancel</button>
                        <button onClick={this.onSign} className="btn btn-outline-success">Finish!</button>
                    </div>
                </div>

            </Modal>

        );
    }
}

SignModalConfirm.propTypes = {
    showModalConfirm          : PropTypes.bool.isRequired,
    toggleModalConfirm        : PropTypes.func.isRequired,
    toggleModalDisclosures    : PropTypes.func.isRequired,
    toggleModaonSignDisclosures    : PropTypes.func.isRequired,
}

export default SignModalConfirm
