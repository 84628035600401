/*
Documentation

this file handles password recovery by sending a hashed security code to a users email
it then verifies the code and lets them update their password

*/

import classnames from "classnames";
import owasp from 'owasp-password-strength-test';
import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import { Helmet } from 'react-helmet'
import keys from 'keys';

import _auth from '_functions/auth';

class Forgot extends React.Component {

	state = {
        error: false,
        showEmail: true,
        showCodeVerification: false,
        showPasswordUpdate: false,
        success: null
    };

    //on enter simulate the form being submitted for better UI
	_handleKeyDown = (e, type) => {
		if (e.key === 'Enter') {

            if(type === 'showEmail') {
                document.getElementById("showEmailButton").click();
            }

            if(type === 'showCodeVerification') {
                document.getElementById("showCodeVerificationButton").click();
            }

            if(type === 'showPasswordUpdate') {
                document.getElementById("showPasswordUpdateButton").click();
            }

		}
	}

    showEmail = () => {
        this.setState({
            showEmail: true,
            showCodeVerification: false,
            showPasswordUpdate: false
        })
    }

    showCodeVerification = () => {
        this.setState({
            showEmail: false,
            showCodeVerification: true,
            showPasswordUpdate: false
        })
    }

    showPasswordUpdate = () => {
        this.setState({
            showEmail: false,
            showCodeVerification: false,
            showPasswordUpdate: true
        })
    }


    onSendCode = async () => {

        this.setState({error: null})

        const email = this.state.email;


        if(!this.state.email) {
            this.setState({error: 'Please Add Your Email'});
            return;
        }

        //send a security code to the server
        //do not console.log this response in production as it holds
        //the code that was created and hashed
        const createCode = await _auth.security_code.create(email)


        if(createCode.success) {

            this.showCodeVerification()

        } else {

            this.setState({error: createCode.message})

        }

    }

    onConfirmedCode = async  () => {

        this.setState({error: null})

        const code = this.state.code;


        if(!this.state.code) {
            this.setState({error: 'Please enter the code sent to your email'});
            return;
        }

        //confirm the security code they entered is valid
        //do not console.log this response in production as it holds
        //the code that was created and hashed
        const confirmedCode = await _auth.security_code.validate(this.state.email, code)


        if(confirmedCode.success) {

            this.setState({user_id: confirmedCode._id})
            this.showPasswordUpdate()

        } else {

            this.setState({error: confirmedCode.message})

        }

    }

    onPasswordSet = async () => {

        this.setState({error: null})

        if(this.state.password_confirm !== this.state.password) {

            this.setState({error: 'Passwords do not match'})
            return;
        }

        //update the users password
        const updatePassword =  await _auth.password.update(this.state.user_id, this.state.password, this.state.password_confirm)

        if(updatePassword.success) {

           this.setState({success: true})

        } else {

            this.setState({error: updatePassword.message})

        }

    }



    onPasswordChange = (e, stateName) => {

        let pt = this.state.passwordTest

        if(stateName === 'password') {
            pt = owasp.test(e.target.value)
        }

        let newState = Object.assign({}, {
            ...this.state,
            passwordTest: pt
        });
        newState[stateName] = e.target.value;

        if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
        } else {
            newState[stateName + "State"] = "valid";
        }

        this.setState({ ...newState });

    };


	render() {

		if(this.state.shouldRedirect) {
			return <Redirect to={this.state.shouldRedirect} />
        }

        const pt = this.state.passwordTest;

		return (

		<>

            <Helmet>
                <title>Forgot Password</title>
                <meta name="description" content="Forgot Password" />
            </Helmet>

            <div className="header  py-7 py-lg-8 pt-lg-9">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">

                                <img style={{width: 400, maxWidth: '100%'}} alt="..."  src={keys.LOGO} />
                                <p className="text-lead text-white mt-3">We all do it too, just fill out the form below.</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

			<Container className="mt--9 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">
						<Card className="bg-white border-0 mb-0" style={{boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px'}}>
							<CardBody className="px-lg-5 py-lg-5">

                                <div className="text-center text-muted mb-4">
                                    <p className="font-weight-bold">
                                        {this.state.showEmail && 'Enter your email'}
                                        {this.state.showCodeVerification && 'Enter the code sent to your email.'}
                                        {this.state.showPasswordUpdate && 'Enter your new password.'}
                                        {this.state.success && <> <br/> <Link to="/auth/login" className="text-success"><b>Your password has been updated, click here to login.</b></Link> </>}
                                    </p>
								</div>

                                {this.state.showEmail && (
                                <>
                                <FormGroup className={classnames("mb-3", { focused: this.state.focusedEmail })} >
                                    <InputGroup className="input-group-merge input-group-alternative">

                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83" />
                                            </InputGroupText>
                                        </InputGroupAddon>

                                        <Input
                                            onKeyDown={(e) => this._handleKeyDown(e, 'showEmail')}
                                            placeholder="Email"
                                            type="email"
                                            name="email"
                                            value={this.state.email	 || ''}
                                            onChange={(e) => this.setState({email: e.target.value})}
                                            onFocus={() => this.setState({ focusedEmail: true })}
                                            onBlur={() => this.setState({ focusedEmail: false })}
                                        />

                                    </InputGroup>
                                </FormGroup>

                                <div className="text-center">
                                    {this.state.error && <span className="text-danger small">{this.state.error}<br /></span>}
                                    <Button id="showEmailButton" onClick={this.onSendCode} className="my-4" color="info" type="button">
                                        Send Recovery Code
                                    </Button>
                                </div>
                                </>
                                )}

                                {this.state.showCodeVerification && (
                                <>
                                <FormGroup className={classnames("mb-3", { focused: this.state.focusedCode })} >
                                    <InputGroup className="input-group-merge input-group-alternative">

                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>

                                        <Input
                                            onKeyDown={(e) => this._handleKeyDown(e, 'showCodeVerification')}
                                            placeholder="Code"
                                            type="number"
                                            value={this.state.code || ''}
                                            onChange={(e) => this.setState({code: e.target.value})}
                                            onFocus={() => this.setState({ focusedCode: true }) }
                                            onBlur={() => this.setState({ focusedCode: false })}
                                        />

                                    </InputGroup>
                                </FormGroup>

                                <div className="text-center">
                                    {this.state.error && <span className="text-danger small">{this.state.error}<br /></span>}
                                    <Button id="showCodeVerificationButton" onClick={this.onConfirmedCode} className="my-4" color="info" type="button">
                                        Verify Code
                                    </Button>
                                </div>
                                </>
                                )}

                                {this.state.showPasswordUpdate && (
                                <>
                                <FormGroup className={classnames({     focused: this.state.focusedPassword })} >

                                    {this.state.passwordState === "invalid" && (
                                        <div className="text-danger font-italic"> <small> Required</small> </div>
                                    )}

                                    {pt && pt.optionalTestErrors && pt.optionalTestErrors.length ? (
                                        <>

                                    { pt.optionalTestErrors.map((p, index) => {
                                            return <div key={index} className="text-danger font-italic"> <small> {p}</small> </div>
                                        })}
                                        </>

                                    ): null}

                                    <InputGroup className="input-group-merge input-group-alternative">

                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>

                                        <Input
                                            onKeyDown={(e) => this._handleKeyDown(e, 'showPasswordUpdate')}
                                            placeholder="Password"
                                            type="password"
                                            value={this.state.password || ''}
                                            onChange={e => this.onPasswordChange(e, "password") }
                                            onFocus={() => this.setState({ focusedPassword: true })}
                                            onBlur={() => this.setState({ focusedPassword: false })  }
                                        />

                                    </InputGroup>

                                </FormGroup>

                                    <FormGroup className={classnames({ focused: this.state.focusedPassword })} >

                                    {this.state.password_confirmState === "invalid" && (
                                        <div className="text-danger font-italic"> <small> Required</small> </div>
                                    )}


                                    <InputGroup className="input-group-merge input-group-alternative">

                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>

                                    <Input
                                        onKeyDown={(e) => this._handleKeyDown(e, 'showPasswordUpdate')}
                                        placeholder="Confirm Password"
                                        type="password"
                                        value={this.state.password_confirm || ''}
                                        onChange={e => this.onPasswordChange(e, "password_confirm") }
                                        onFocus={() => this.setState({ focusedPassword: true })}
                                        onBlur={() => this.setState({ focusedPassword: false })}
                                />

                                    </InputGroup>

                                </FormGroup>

                                <div className="text-center">
                                    {this.state.error && <span className="text-danger small">{this.state.error}<br /></span>}
                                    <Button id="showPasswordUpdateButton" onClick={this.onPasswordSet} className="my-4" color="info" type="button">
                                        Update Password
                                    </Button>
                                </div>

                                </>
                                )}

							</CardBody>
						</Card>

						<Row className="mt-3">

                            <Col xs="12" className="text-center">
                                <Link className="text-light" to="/auth/login" >
									<small>Login</small>
								</Link>
							</Col>

						</Row>
					</Col>
				</Row>
			</Container>
		</>
		);
	}
}

export default Forgot

