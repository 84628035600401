/*
Documentation

this is the wrapping component for the dashboard routes
it should check to see if a user is logged in
if not kick them out the /auth/login

it will also load any data needed for the dashboard to work
before rendering its routes

*/


import React from "react";
import { connect } from 'react-redux';
import {  Redirect, Route, Switch } from "react-router-dom";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar/index.js";
import routes from "./routes";

import stackLogger from 'database/stackLogger';

class Dashboard extends React.Component {
    state = {
        loaded: false
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {

        return (
            <Route
                exact path={`/dashboard${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })

    componentDidMount = async () => {

        //if the user is not logged in redirect them
        if(!this.props.viewing_user) {
            return this.setState({shouldRedirect: '/auth/login?redirect=' + window.location.pathname});
        }

        this.setState({loaded: true})

    }


    componentDidCatch = (e) => {

        console.log(e);
        stackLogger(e);

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    render() {

        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }
        if(!this.state.loaded) return <div />

        return (
            <>

                <div className="wave" />

                <div className="main-content" ref="mainContent" onClick={this.closeSidenav} >

                    <Navbar {...this.props} />

                    <Switch>
                        {this.getRoutes(routes)}
                        <Redirect from="*" to="/dashboard" />
                    </Switch>

                    <Footer />

                </div>


            </>

        );
    }
}

const mapStateToProps = state => {
	return {
	  viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(Dashboard);
