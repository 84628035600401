import apiWorker from '../apiWorker'

export default {
    
    updateOptOut  : (contact_id, data)      =>  apiWorker('patch',  `/api/v1/core/contacts/update/opt_out/${contact_id}`, data),
    update        : (contact_id, data)      =>  apiWorker('patch',  `/api/v1/core/contacts/update/${contact_id}`, data),
    create        : (data)                  =>  apiWorker('post',   `/api/v1/core/contacts/create`, data),
    findById      : (contact_id, hideError) =>  apiWorker('get', `/api/v1/core/contacts/find/${contact_id}`, null, hideError),
    findByEmail   : (email, hideError)      =>  apiWorker('get', `/api/v1/core/contacts/findByEmail/${email}`, null, hideError),
    findByPhone   : (phone, hideError)      =>  apiWorker('get', `/api/v1/core/contacts/findByPhone/${phone}`, null, hideError),
    queryCalendar : (contact_id)            => apiWorker('get',   `/api/v1/core/calendar/query/byContacts/${contact_id}`),
    fetchByIdentifier : (identifier)            => apiWorker('get',   `/api/v1/core/contacts/fetchByIdentifier/${identifier}`, null, true),

}
