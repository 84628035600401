export const calculateInvoiceFees = (invoice) => {

    let total_fees = 0

    if(!invoice) { return total_fees }

    const fees = invoice.fees

    if(fees && fees.length ) {
        
        fees.forEach(f => {

            if(f.status === 'billable') {
                total_fees = total_fees + parseFloat(f.amount)
            }

        })

    }

    return total_fees

}

export const calculateInvoiceExpenses = (invoice) => {

    let total_expenses = 0

    if(!invoice) { return total_expenses }

    const expenses = invoice.expenses

    if(expenses && expenses.length ) {
        
        expenses.forEach(f => {

            const  amount = parseFloat(f.amount);
            const  quantity = parseFloat(f.quantity);

            const total = amount * quantity

            total_expenses = total_expenses + total

        })

    }

    return total_expenses

}

// get the total expenses for an individual expense item
export const calculateExpenseTotal = (expense) => {

    let total = 0;

    if(expense.amount && expense.quantity) {

        let amount = parseFloat(expense.amount);
        let quantity = parseFloat(expense.quantity);

        total = amount * quantity

    }

    return total

}

export const calculateCreditTotal = (invoice) => {

    let total_credits = 0

    if(!invoice) { return total_credits }

    const credits = invoice.credits

    if(credits && credits.length ) {
        
        credits.forEach(f => {

            total_credits = total_credits + parseFloat(f.amount)

        })

    }

    return total_credits

}

/*
Documentation

Calculate total to be billed for invoice
1. add fees and expenses to be billed to client
2. subtract discount first (fixed or percent)
3. subtract credits
4. return final value

*/

export const calculateInvoiceTotal = (invoice) => {

    const fees = calculateInvoiceFees(invoice)
    const expenses = calculateInvoiceExpenses(invoice)
    const credits = calculateCreditTotal(invoice)

    const income = fees + expenses;

    let income_minus_discounts = income;

    if(invoice.discounts && invoice.discounts.length) {

        let discount = invoice.discounts[0]
        if(discount.type === 'fixed') {
            income_minus_discounts = income_minus_discounts - discount.amount
        } else {
            income_minus_discounts = income_minus_discounts * (1 - (parseFloat(discount.amount) / 100))
        }

    }

    const income_minus_credits = income_minus_discounts - credits;

    return income_minus_credits

}