import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle'
import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, Container, CardTitle, CardHeader } from "reactstrap";
import _contact_alerts from '_functions/contact_alerts';
import moment from 'moment'

import reactHTMLParser from 'react-html-parser'

class ContactAlerts extends React.Component {

    state = {
        eventToShow: null,
        showModal: false,
        appointments: null,
    }

    componentDidMount = async () => {

        const contact_alerts = await _contact_alerts.findByContact(this.props.viewing_user._id)
        if(contact_alerts.data) this.setState({contact_alerts: contact_alerts.data})

    }

    render() {

        const { contact_alerts } = this.state
        if(!contact_alerts) return <div className="py-6"><Circle /></div>

        return (

            <Container>

            <Helmet>
                <title>My Alerts</title>
                <meta name="description" content="My Alerts" />
            </Helmet>

            <DashHeaderOpen
                icon="fas fa-home"
                icon_link="/dashboard"
                title={<span>Case Alerts</span>} breadcrumb_1="View"
            />

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">My Alerts</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">

                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Alert</th>
                                <th className="text-right">Resolved</th>
                            </tr>
                        </thead>

                        <tbody>
                        {contact_alerts && contact_alerts.length ? contact_alerts.map(alert => (
                            <tr key={alert._id}>
                                <td style={{width: 110, minWidth: 110}}>
                                    <div>{moment.unix(alert.created_at).format('MM/DD/YY')}</div>
                                    <div>{moment.unix(alert.created_at).format('h:mm A')}</div>
                                </td>
                                <td className="text-capitalize">{reactHTMLParser(alert.value)}</td>
                                <td className="text-right">
                                    {alert.resolved ?
                                        <span><i className="fas fa-check text-success" /></span> :
                                        <i className="fas fa-times text-danger" />}
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td><i className="fas fa-check text-success mr-2" /> You have no alerts on your account.</td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                        </tbody>

                    </table>
                </div>

            </Card>


        </Container>


        );
    }
}



const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ContactAlerts);
