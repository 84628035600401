/*
Documentation

This is the navbar for the dashboard layout

*/

import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar, NavItem, NavLink } from "reactstrap";
import _contact_alerts from '_functions/contact_alerts';


class NavbarBottom extends React.Component {

	state = {
        contact_alerts: null
    }

    componentDidMount = async () => {

        const contact_alerts = await _contact_alerts.findByContact(this.props.viewing_user._id)
        if(contact_alerts.data) this.setState({contact_alerts: contact_alerts.data})

    }

	render() {

        let { contact_alerts } = this.state

        // only show the number of non resolved case alerts
        if(contact_alerts) contact_alerts = contact_alerts.filter(a => a.resolved === false)


    	return (

			<>

            <div className="under-nav">
				<Navbar className="navbar-expand border-bottom navbar"  >
					<Container>
						<Nav className="align-items-center d-none d-md-block" navbar>

							<NavLink tag={Link} to="/dashboard">
								<NavItem tag="span" className="text-white" style={{cursor: 'pointer'}}>
									<i className="fas fa-home mr-2" />
									Dashboard
								</NavItem>
							</NavLink>

							<NavLink tag={Link} to="/dashboard/billing/methods">
								<NavItem tag="span" className="text-white" style={{cursor: 'pointer'}}>
									<i className="fas fa-money-check-alt mr-2" />
									My Cards
								</NavItem>
							</NavLink>

							<NavLink tag={Link} to="/dashboard/billing/payments">
								<NavItem tag="span" className="text-white" style={{cursor: 'pointer'}}>
									<i className="fas fa-dollar-sign mr-2" />
									My Payments
								</NavItem>
							</NavLink>

                            <NavLink tag={Link} to="/dashboard/billing/invoices">
								<NavItem tag="span" className="text-white" style={{cursor: 'pointer'}}>
									<i className="fas fa-file-invoice mr-2" />
									Invoices
								</NavItem>
							</NavLink>

							<NavLink tag={Link} to="/dashboard/calendar/calendar">
								<NavItem tag="span" className="text-white" style={{cursor: 'pointer'}}>
									<i className="fas fa-calendar mr-2" />
									Calendar
								</NavItem>
							</NavLink>

							<NavLink tag={Link} to="/dashboard/document_select">
								<NavItem tag="span" className="text-white" style={{cursor: 'pointer'}}>
									<i className="fas fa-file mr-2" />
									Documents
								</NavItem>
							</NavLink>

                            <NavLink tag={Link} to="/dashboard/alerts/all">
								<NavItem tag="span" className="text-white" style={{cursor: 'pointer'}}>
									<span className="text-warning font-weight-bold">
                                    <i className="fas fa-exclamation-triangle mr-2" />
									Alerts{' '}
                                    {contact_alerts ? <span>({contact_alerts.length})</span> : null}
                                    </span>
								</NavItem>
							</NavLink>

						</Nav>
					</Container>
				</Navbar>
			</div>


			</>

    	);
  	}
}

const mapStateToProps = state => {
  	return {
    	viewing_user: state.auth.viewing_user,
  	};
};

export default connect(mapStateToProps, '')(NavbarBottom);
