/*
Documentation

takes an object with given_name and family_name properties and tries to match them up
into one coherent string

*/

export default (obj) => {

    if(!obj) return "Not Found"

    if(obj.given_name && obj.family_name) {

        return obj.given_name + ' ' + obj.family_name

    } else if(obj.given_name) {

        return obj.given_name

    } else if(obj.family_name) {

        return obj.family_name

    }

    return "Not Found"

}
