/*

scripts for appointment type
rescheduling
timer



*/

import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle, FormGroup, Input, Container } from "reactstrap";
import _forms from '_functions/forms';

class FormsFinish extends Component {

    state = {
        branch_questions: {}
    }

    onInputChange = (value, text) => {

        let branch_questions = Object.assign({}, this.state.branch_questions)
        branch_questions[text] = value

        this.setState({branch_questions})

    }

    renderInput = (question) => {

        if(question.type === 'text' || question.type === 'textarea' || question.type === 'number') {

            return (
                <Input
                    value={this.state.branch_questions[question.text] || ''}
                    onChange={(e) => this.onInputChange(e.target.value, question.text)}
                    type={question.type}
                />
            )

        } else {

            return (
                <Input
                    value={this.state.branch_questions[question.text] || ''}
                    onChange={(e) => this.onInputChange(e.target.value, question.text)}
                    type="select"
                >
                    <option></option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </Input>
            )

        }

    }

    questionMarkup = (question, i) => {

        return (
            <FormGroup key={`${question.text }_${i}`}>
                <label className="form-control-label font-weight-normal">
                    {question.text}
                    {question.type === 'number' ? ' (Enter Numbers Only)' : null}
                </label>

                {this.renderInput(question)}

                {question.hint ? (
                    <div className="bg-secondary p-3 mt-3">
                        <p className="text-sm mb-0"><b>Hints:</b><br />{question.hint}</p>
                    </div>
                ) : null}

            </FormGroup>
        )

    }

    renderQuestions = (questions = null) => {

        const { form, branch_questions } = this.state;

        let markup = []
        let shouldRender = true;
        let markupBranch = []
        let shouldFinish = false

        if(form) {

            // set questions to either the first row of form questions or the questions passed in
            questions = questions ? questions : form.questions;

            questions.forEach((question, i) => {


                if(shouldRender) {

                    // if we have children stop the render
                    if(question.children && question.children.length) {

                        // if question is not answered put a hard stop to it
                        if(!branch_questions[question.text]) {

                            shouldRender = false

                        // if the answer to the question is no render the next branch of children
                        } else if(branch_questions[question.text] === 'no') {

                            shouldRender = false
                            markupBranch = this.renderQuestions(question.children)

                        } else if(branch_questions[question.text] === 'yes') {

                            shouldFinish = true

                        }

                    } else {

                        if(!questions.find(q =>  q.children )) {
                            shouldFinish = true
                        }


                    }

                    markup.push(this.questionMarkup(question, i))

                }

            })

        }

        markup = markup.concat(markupBranch)

        if(shouldFinish) {
            markup.push(<div key={'finish'} className="alert alert-success"><i className="fas fa-check text-success mr-2" /> End Form</div>)
        }

        return markup

    }

    componentDidMount = async () => {

        const form = await _forms.findById(this.props.match.params.form)

        this.setState({form: form.data})

    }

    render() {

        const { form } = this.state

        if(!form) return <div></div>

        return (

            <Container className="pt-6">

                <Card className="card-color card-warning">

                    <CardHeader>
                        <CardTitle className="mb-0">Appointment Flow</CardTitle>
                    </CardHeader>

                    <CardBody>

                        {this.renderQuestions()}

                    </CardBody>

                </Card>

            </Container>

        )

    }

}

export default FormsFinish;
