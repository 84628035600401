/*
Documentation

This page handles updating a users profile

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import States from 'components/markup/inputs/States';
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row } from "reactstrap";
import { setViewingUser } from 'store/functions/auth/auth';
import { validatePhoneNumber } from 'utils/validation';

import _auth from   '_functions/auth';
import _contacts from  '_functions/contacts';

const required_form_fields = [
    'given_name',
    'family_name',
    'email',
    'phone',
    'address_line_1',

    'city',
    'state',
    'postal_code'
]

class AccountSettings extends React.Component {

    state = {
        user: Object.assign({}, this.props.viewing_user),
        canSave: true
    }

    validateEmail =  async (email) => {
        return new Promise (async resolve => {

            this.setState({validatingEmail: true})

            const emailInSystem = await _contacts.findByEmail(email)
            let emailAlert = null

            if(emailInSystem.success && emailInSystem.data) {

                   // set the error message if we need it
                   let errorMessage = (
                    <div className="alert alert-danger py-2 px-3">
                        {`The email you have entered is already associated to another contact: "${email}".`}

                    </div>
                )

                 // if the contact is in the system but we are creating a contact we have an error
                 if(!this.props.viewing_user) {

                    emailAlert = errorMessage

                // if the contact is in the system but we are updating a contact don't show an error for the same email address
                } else if(this.props.viewing_user._id !== emailInSystem.data._id) {

                    emailAlert = errorMessage

                }

            }

            this.setState({validatingEmail: false, emailAlert})
            resolve(emailAlert ? false : true)


        })
    }

    validatePhone = async (phone) => {
        return new Promise (async resolve => {

            this.setState({validatingPhone: true})

            let formattedPhone = validatePhoneNumber(phone)

            if(formattedPhone) phone = formattedPhone

            // see if this phone is in the system
            const phoneInSystem = await _contacts.findByPhone(phone)
            let phoneAlert = null;

            if(phoneInSystem.success && phoneInSystem.data) {

                // set the error message if we need it
                let errorMessage = (
                    <div className="alert alert-danger py-2 px-3">
                        {`The phone number you have entered is already associated to another contact: "${phone}".`}

                    </div>
                )

                // if the contact is in the system but we are creating a contact we have an error
                if(!this.props.viewing_user) {
                    phoneAlert = errorMessage

                // if the contact is in the system but we are updating a contact don't show an error for the same email address
                } else if(this.props.viewing_user._id !== phoneInSystem.data._id) {
                    phoneAlert = errorMessage
                }

            }

            this.setState({validatingPhone: false, phoneAlert})
            resolve(phoneAlert ? false : true)

        })
    }


    fireAlert = (error, text) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success={error ? false : true}
                    danger={!error ? false : true}
                    title={error ? 'Error' : 'Success'}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    {error ?  'Looks like something went wrong.' : text }
                </ReactBSAlert>
            )
        });
    };


    hideAlert = () => this.setState({ alert: null });

    onSave = async () => {

        this.setState({canSave: false})
        //copy state
        let newState = Object.assign({}, this.state.user);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        //remove new_password fields
        delete newState.new_password
        delete newState.new_password_confirm
        //remove profile picture
        delete newState.picture_url
        if(!newState.default_account_vault_id) delete newState.default_account_vault_id


        this.setState({ user: newState });

        if(!errors) {

            Promise.all([
                this.validateEmail(newState.email),
                this.validatePhone(newState.phone)
            ]).then(async values => {

                if(values[0] === false || values[1] === false) {
                    console.log('not updated')
                    return this.setState({canSave: true})
                }

                const updated = await _contacts.update(this.props.viewing_user._id, newState)

                // if we have an error the alert will return an error message
                // we only need a success message
                this.fireAlert(!updated.success, 'Your profile has been updated successfully.')
                // setViewingUser()

            })

        }

        //after load make sure button work again
        this.setState({canSave: true})

    }

    onInputChange = (e, stateName) => {

        let newState = Object.assign({}, this.state.user);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
          if (e.target.value) {
            newState.checkboxState = "valid";
          } else {
            newState.checkboxState = "invalid";
          }
        } else {
          if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }
        }

        this.setState({ user: newState });
    };

    onPasswordUpdate = async  () => {

        this.setState({error_password: false})

        const user = {...this.state.user, _id: this.props.viewing_user._id };

        const updated = await _auth.password.update(user._id, user.new_password, user.new_password_confirm)

        if(updated.success) {

            this.fireAlert(false, 'Your password has been successfully changed')
            this.setState({ user: {
                ...this.state.user,
                new_password: null,
                new_password_confirm: null
            }})

        } else {

            this.setState({error_password: updated.message})

        }

    }

    onMediaSelect = async (err, url) => {

        if(err) {
            this.fireAlert(true)
            console.log(err);
            return;
        }

        const updated = await _contacts.update(this.props.viewing_user._id, {  picture_url: url })

        this.fireAlert(!updated.success, "Your avatar has been updated successfully.");

        setViewingUser()

    }

  render() {

        return (
            <>

            <Container>

                {this.state.alert}

                <Helmet>
                    <title>Settings</title>
                    <meta name="description" content="Settings" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Settings</span>} breadcrumb_1="Profile"
                />

                <Row>

                    <Col lg={8}>

                        <Card>

                            <CardHeader>
                                <h3 className="mb-0">My Account</h3>
                            </CardHeader>

                            <CardBody>

                                <h6 className="heading-small text-muted mb-4">User information</h6>

                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="given_name" >
                                                First name
                                            </label>
                                            <Input
                                                id="given_name"
                                                value={this.state.user.given_name || ''}
                                                placeholder="First name"
                                                type="text"
                                                valid={ this.state.user.given_nameState === "valid" }
                                                invalid={ this.state.user.given_nameState === "invalid" }
                                                onChange={e => this.onInputChange(e, "given_name") }
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="family_name" >
                                                Last name
                                            </label>
                                            <Input
                                                id="family_name"
                                                value={this.state.user.family_name || ''}
                                                placeholder="Last name"
                                                type="text"
                                                valid={ this.state.user.family_nameState === "valid" }
                                                invalid={ this.state.user.family_nameState === "invalid" }
                                                onChange={e => this.onInputChange(e, "family_name") }
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="email">
                                                Email address
                                            </label>
                                            <Input
                                                id="email"
                                                value={this.state.user.email || ''}
                                                placeholder="email@example.com"
                                                type="email"
                                                valid={ this.state.user.emailState === "valid" }
                                                invalid={ this.state.user.emailState === "invalid" }
                                                onChange={e => this.onInputChange(e, "email") }
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="phone">
                                                Phone
                                            </label>
                                            <Input
                                                id="phone"
                                                value={this.state.user.phone || ''}
                                                placeholder="555-555-5555"
                                                type="tel"
                                                valid={ this.state.user.phoneState === "valid" }
                                                invalid={ this.state.user.phoneState === "invalid" }
                                                onChange={e => this.onInputChange(e, "phone") }

                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <hr className="my-4" />
                                <h6 className="heading-small text-muted mb-4">Address</h6>

                                <Row>

                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="address_line_1" >
                                                Address Line 1
                                            </label>
                                            <Input
                                                id="address_line_1"
                                                value={this.state.user.address_line_1 || ''}
                                                placeholder="198 Manhattan Ave"
                                                type="text"
                                                valid={ this.state.user.address_line_1State === "valid" }
                                                invalid={ this.state.user.address_line_1State === "invalid" }
                                                onChange={e => this.onInputChange(e, "address_line_1") }
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="address_line_2" >
                                                Address Line 2
                                            </label>
                                            <Input
                                                id="address_line_2"
                                                value={this.state.user.address_line_2 || ''}
                                                placeholder="Apt D"
                                                type="text"
                                                valid={ this.state.user.address_line_2State === "valid" }
                                                invalid={ this.state.user.address_line_2State === "invalid" }
                                                onChange={e => this.onInputChange(e, "address_line_2") }
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>

                                    <Col lg="4">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="city" >
                                                City
                                            </label>
                                            <Input
                                                id="city"
                                                value={this.state.user.city || ''}
                                                placeholder="Manhattan"
                                                type="text"
                                                valid={ this.state.user.cityState === "valid" }
                                                invalid={ this.state.user.cityState === "invalid" }
                                                onChange={e => this.onInputChange(e, "city") }
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>

                                    <Col lg="4">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="state" >
                                                State
                                            </label>
                                            <Input
                                                id="state"
                                                type="select"
                                                value={this.state.user.state || ''}
                                                valid={ this.state.user.stateState === "valid" }
                                                invalid={ this.state.user.stateState === "invalid" }
                                                onChange={e => this.onInputChange(e, "state") }
                                            >
                                                <option value="">Select State</option>
                                                <States />
                                            </Input>
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>

                                    <Col lg="4">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="postal_code" >
                                                Postal code
                                            </label>
                                            <Input
                                                id="postal_code"
                                                value={this.state.user.postal_code || ''}
                                                placeholder="Apt D"
                                                type="text"
                                                valid={ this.state.user.postal_codeState === "valid" }
                                                invalid={ this.state.user.postal_codeState === "invalid" }
                                                onChange={e => this.onInputChange(e, "postal_code") }
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </FormGroup>
                                    </Col>

                                </Row>

                                {this.state.phoneAlert}
                                {this.state.emailAlert}

                                <div className="text-right pt-4">
                                    <Button
                                        color="success"
                                        onClick={this.onSave}
                                        disabled={this.state.canSave ? false : true}
                                    >
                                        Save Profile
                                    </Button>
                                </div>

                            </CardBody>

                        </Card>

                    </Col>

                    <Col lg={4}>
                        <Card>

                            <CardHeader>
                                <h3 className="mb-0">Security</h3>
                            </CardHeader>

                            <CardBody>

                                <h6 className="heading-small text-muted mb-4">Change Password</h6>

                                <FormGroup>
                                    <label className="form-control-label" htmlFor="new_password" >
                                        New Password
                                    </label>
                                    <Input
                                        id="new_password"
                                        value={this.state.user.new_password || ''}
                                        placeholder=""
                                        type="password"
                                        onChange={e => this.onInputChange(e, "new_password") }
                                    />
                                    {this.state.error_password ? <div className="text-danger">{this.state.error_password}</div> : null}
                                </FormGroup>

                                <FormGroup>
                                    <label className="form-control-label" htmlFor="new_password_confirm" >
                                        Confirm New Password
                                    </label>
                                    <Input
                                        id="new_password_confirm"
                                        value={this.state.user.new_password_confirm || ''}
                                        placeholder=""
                                        type="password"
                                        onChange={e => this.onInputChange(e, "new_password_confirm") }
                                    />
                                </FormGroup>

                                <hr />

                                <p className="text-sm">When changing your password make sure to not reuse any that you have in the past and ensure that it is not easily guessed by other trying to access your account.</p>

                                <div className="text-right pt-3">
                                    <Button
                                        color="success"
                                        onClick={this.onPasswordUpdate}
                                        disabled={this.state.canSave ? false : true}
                                    >
                                        Update Password
                                    </Button>
                                </div>

                            </CardBody>

                        </Card>
                    </Col>

                </Row>

            </Container>

            </>
        );
    }
}



const mapStateToProps = state => {
    return {
      viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountSettings);
