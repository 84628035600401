import { architeckCall } from 'database';

export default (_id, password, password_confirm) => {
    return new Promise (async resolve => {
    
        const updatePassword = await architeckCall({
            shouldNotShowAndLogError: true, 
            method:'post',
            url: '/api/v1/auth/contact/password_update',
            data: { 
                password,
                password_confirm,
                _id,
            }
        })

        resolve(updatePassword)
    
    })
}