import actionsSecurityCodeCreate from './actions/security_code/create';
import actionsSecurityCodeValidate from './actions/security_code/validate';

import actionsPasswordUpdate from './actions/password/update';
import actionsPasswordValidate from './actions/password/validate';
import actionsGetSignup from './actions/password/getSignup';

import actionsStatusLogout from './actions/status/logout';
import actionsStatusIsLoggedIn from './actions/status/isLoggedIn';

export default {

    security_code: {
        create: actionsSecurityCodeCreate,
        validate: actionsSecurityCodeValidate
    },

    password: {
        update: actionsPasswordUpdate,
        validate: actionsPasswordValidate,
        getSignup: actionsGetSignup,
    },

    status: {
        logout: actionsStatusLogout,
        isLoggedIn: actionsStatusIsLoggedIn
    }

}
