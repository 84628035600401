import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { Container, Card, CardHeader, CardBody, CardTitle, Row, Col, CardFooter } from "reactstrap";
import { Redirect, Link } from 'react-router-dom';
import { formatMoney } from 'utils/currency';
import Circle from 'components/markup/loading/Circle'

import _invoices from '_functions/invoices';
import _documents from '_functions/documents';
import _cases from '_functions/cases';

const TextRow = (props) => (
    <Row>

        <Col md={4}>
            <p className="text-sm mb-0 font-weight-bold">{props.title}</p>
        </Col>

        <Col md={8}>
            <p className="text-sm mb-0 text-capitalize">{props.value}</p>
        </Col>

    </Row>
)


class CasesView extends React.Component {

    state = {
        invoices: null,
        documents: null,
        case: null
    }

    redirect = () => this.setState({shouldRedirect: '/dashboard'})

    findCase = async (case_id) => {

        const _case = await _cases.findById(case_id)
        if(_case.success) {

            let required_documents = _case.data.missing_docs ? _case.data.missing_docs.filter(d => !d.status) : []
            let finished_documents = _case.data.missing_docs ? _case.data.missing_docs.filter(d => d.status === 'completed') : []
            this.setState({case: _case.data, required_documents, finished_documents})

        }

    }

    findDocuments = async (case_id) => {

        const found_documents = await _documents.findByCase(case_id)

        if(found_documents.success) {

            if(found_documents) return this.setState({documents: found_documents.data})

        }

    }
    findInvoices = async (case_id) => {

        // find all invoices for this case
        const found_invoices = await _invoices.findByCase(case_id)

        if(found_invoices.success) {
            
            // set invoices to only onces that have been sent
            const data = found_invoices.data.filter(i => i.status === 'sent')
            return this.setState({ invoices: data})

        }

    }


    componentDidMount = async() => {

        const case_id = this.props.match.params._id

        this.findCase(case_id)
        this.findInvoices(case_id)
        this.findDocuments(case_id)

    }

    render() {

        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }

        const _case = this.state.case;
        const { invoices, documents, required_documents, finished_documents } = this.state;

        // if data hasn't loaded return nothing
        if(!_case || !invoices || !documents) return (
            <div className="py-6"><Circle /></div>
        )

        const caseRemaining = _invoices.calculate.totalRemainingByCase(invoices)
        return (

            <Container>

                <Helmet>
                    <title>Cases View</title>
                    <meta name="description" content="Cases View" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Cases</span>} breadcrumb_1={_case.name}
                    actionComponent={(
                        <Link to="/dashboard" className="btn btn-outline-success"><i className="fas fa-arrow-left mr-2" />All Cases</Link>
                    )}
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Help Center</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm">
                            When viewing a case you can see all related details, who your lead attorney is, any documents that have been created for this case, and your current billables for the case. If you have any outstanding invoices you may make a payment by clicking the green button
                            {' '}<span className="text-success font-weight-bold">"<span className="text-underline">Make A Payment</span>"</span>{' '}
                            on the bottom right corner of the screen.
                        </p>
                    </CardBody>

                </Card>

                <Row>

                    <Col lg={6}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0">Case Information</CardTitle>
                            </CardHeader>

                            <CardBody>

                                <TextRow
                                    title="Case Name"
                                    value={_case.name}
                                />

                                {/* <TextRow
                                    title="Lead Attorney"
                                    value={
                                        _case.lead_attorney && _case.lead_attorney.given_name ?
                                        _case.lead_attorney.given_name + ' ' + _case.lead_attorney.family_name :
                                        "Not Found"
                                    }
                                />

                                <TextRow
                                    title="Billing Type"
                                    value={<span className="text-capitalize">{_case.billing_type}</span>}
                                />

                                <TextRow
                                    title="Court"
                                    value={
                                        _case.location_court && _case.location_court.name ? _case.location_court.name : "Not Found"
                                    }
                                /> */}

                                <hr className="my-2" />

                                <TextRow
                                    title="Documents Needed"
                                    value={
                                        required_documents.length
                                    }
                                />
                                <TextRow
                                    title="Documents Finished"
                                    value={
                                        finished_documents.length
                                    }
                                />


                            </CardBody>

                            <CardFooter>
                                <Link to={`/dashboard/cases/view/${_case._id}/documents`} className="btn btn-success ">Upload Case Documents</Link>
                            </CardFooter>

                        </Card>

                    </Col>

                    <Col lg={6}>

                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0">Case Invoices</CardTitle>
                            </CardHeader>

                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead className="bg-secondary">
                                        <tr>
                                            <th>Invoice</th>
                                            <th className="text-right">Paid</th>
                                            <th className="text-right">Total</th>
                                        </tr>
                                    </thead>

                                    <tbody className="text-muted">
                                        {invoices && invoices.length ? invoices.map(i => (
                                            <tr key={i._id}>
                                                <td style={{width: 'calc(100% - 190px)'}}>{i.name}</td>
                                                <td style={{width: 95}} className="text-right">{formatMoney(i.total_paid)}</td>
                                                <td style={{width: 95}} className="text-right">{formatMoney(i.total)}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>There are no current invoices for this case.</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot className="bg-secondary">
                                        <tr>
                                            <td>Balance</td>
                                            <td></td>
                                            <td className="text-right">
                                                {invoices && invoices.length ? (
                                                    formatMoney(caseRemaining)
                                                ) : '$0.00'}
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>


                            {invoices && invoices.length ? (
                                <CardFooter>
                                    <div className="text-right">
                                        <Link to={`/dashboard/billing/pay/${_case._id}`} className="btn btn-success">Make A Payment</Link>
                                    </div>
                                </CardFooter>
                            ) : null}

                        </Card>

                    </Col>

                </Row>

            </Container>

        );
    }
}

export default CasesView
