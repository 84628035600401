/*
Documentation

renders the one time payment form within case view on the billing center

*/

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FormGroup, Input, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { passesDecimalCheck } from 'utils/currency';
import { connect } from 'react-redux';

import _payments from '_functions/payments'
import { formatMoney } from 'utils/currency';
import { toggleStandardLoader } from 'store/functions/system/system'

class ChargeOneTimePayment extends Component {

    state = {
        canSave: true,
    };

    onInputChange = (e, stateName) => {

        let newState = Object.assign({}, this.state);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
            if (e.target.value) {
                newState.checkboxState = "valid";
            } else {
                newState.checkboxState = "invalid";
            }
        } else {
            if (e.target.value === "") {
                newState[stateName + "State"] = "invalid";
            } else {
                newState[stateName + "State"] = "valid";
            }
        }

        this.setState(newState);

        if(stateName === 'selected_invoice') {

            const found_invoice = this.props.invoices.find(i => i._id === e.target.value)
            if(found_invoice) this.setState({found_invoice})

        }

    };

    fireAlert = (error, message) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success={error ? false : true}
                    danger={!error ? false : true}
                    title={error ? 'Error' : 'Success'}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    {error ?  message ? message: 'Card declined, please try again with a valid card or double check your current card details.' : 'Payment Charged Successfully.' }
                </ReactBSAlert>
            )
        });
    };

    hideAlert = () => this.setState({ alert: null });

    //when a new payment is charged
    onChargePayment = async () => {

        const { one_time_payment, account_vault_api_id, selected_invoice, found_invoice } = this.state
        let errors = 0;

        this.setState({
            one_time_paymentState: 'valid',
            account_vault_api_idState: 'valid',
            selected_invoiceState: 'valid',
            canSave: false
        })

        //set state bases on if we have a valid amount
        if(!one_time_payment || !passesDecimalCheck(one_time_payment)) {
            errors++;
            this.setState({one_time_paymentState: 'invalid'})
        }

        //set state bases on if we have a invoice
        if(!selected_invoice || !passesDecimalCheck(selected_invoice)) {
            errors++;
            this.setState({selected_invoiceState: 'invalid'})
        }

        //set state for valid account api id
        if(!account_vault_api_id) {
            errors++;
            this.setState({account_vault_api_idState: 'invalid'})
        }

        //if the amount we are trying to charge is greater than the invoice balance return
        if(parseFloat(one_time_payment) > (found_invoice.total - found_invoice.total_paid)) {
            errors++;
            this.setState({
                cash_paymentState: 'invalid',
                valueError: `The amount to charge cannot be greater than the unpaid balance for the invoice (${formatMoney(found_invoice.total - found_invoice.total_paid)})`
            })

        }

        if(!errors) {

            toggleStandardLoader(true)

            const charge = await _payments.transactions.card(account_vault_api_id, {
                case: this.props.case._id,
                transaction_amount: one_time_payment
            })

            if(charge.success) {
                this.setState({
                    one_time_payment: null,
                    one_time_paymentState: null,
                    account_vault_api_id: null,
                    found_invoice: null,
                    selected_invoice: null,
                    valueError: null,
                    canSave: true
                })
            }

            // send message to user
            this.fireAlert(!charge.success, charge.user_message)
            toggleStandardLoader(false)

        }

        this.setState({canSave: true})


    }


    render() {

        const contact       = this.props.viewing_user
        const { account_vaults, invoices } = this.props
        let { found_invoice, valueError } = this.state

        return (

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0 text-capitalize">Make A Payment</CardTitle>
                    <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 text-info-original" />
                        Note it may take up to a minute after a payment has gone through for it to show up on your account. If you do not see an update to your account after that amount of time try refreshing the page.
                    </p>
                </CardHeader>

                <CardBody>

                    {this.state.alert}

                    <FormGroup>

                        {found_invoice && found_invoice.total === found_invoice.total_paid ? (
                            <div className="alert alert-success">This invoice has been paid in full.</div>
                        ) : found_invoice ? (
                            <div className="alert alert-info"> Selected Invoice Balance: {found_invoice ? formatMoney(found_invoice.total - found_invoice.total_paid): '-'}</div>
                        ) : null}

                        <label className="form-control-label" htmlFor="selected_invoice" >For Invoice</label>

                        <Input
                            id="selected_invoice"
                            value={this.state.selected_invoice || ''}
                            type="select"
                            valid={ this.state.selected_invoiceState === "valid" }
                            invalid={ this.state.selected_invoiceState === "invalid" }
                            onChange={e => this.onInputChange(e, "selected_invoice") }
                        >
                            <option value={null}></option>
                            {invoices.map(i => (
                                <option value={i._id} key={i._id}>{i.name} ({formatMoney(i.total)})</option>
                            ))}
                        </Input>

                        <div className="valid-feedback">Looks good!</div>

                    </FormGroup>


                    <FormGroup>

                        <label className="form-control-label" htmlFor="account_vault_api_id" >Choose Your Payment Method</label>

                        <Input
                            id="account_vault_api_id"
                            value={this.state.account_vault_api_id || ''}
                            type="select"
                            valid={ this.state.account_vault_api_idState === "valid" }
                            invalid={ this.state.account_vault_api_idState === "invalid" }
                            onChange={e => this.onInputChange(e, "account_vault_api_id") }
                        >
                            <option value={null}></option>
                            {account_vaults && account_vaults.length ? account_vaults.map(a => (
                                <option key={a.id} value={a.account_vault_api_id}>

                                    {contact.default_account_vault_id === a.id ? 'DEFAULT CARD: ' : null}
                                    {_payments.cards.getName(a.account_type)}: {a.first_six.slice(0,1)}......{a.last_four} {' '}
                                    Exp: {a.exp_date.slice(0,2)}/{a.exp_date.slice(2,4)}
                                </option>
                            )) : (
                                <option value={null}>This contact does not have a payment method on file.</option>
                            )}

                        </Input>

                        <div className="valid-feedback">Looks good!</div>

                    </FormGroup>


                    <FormGroup>

                        <label className="form-control-label" htmlFor="one_time_payment" >
                            Amount (US Dollars)
                        </label>

                        {valueError ? (
                            <p className="text-sm text-danger font-weight-bold mb-0">
                                {valueError}
                            </p>
                        ) : null}
                        <Input
                            id="one_time_payment"
                            value={this.state.one_time_payment || ''}
                            type="number"
                            valid={ this.state.one_time_paymentState === "valid" }
                            invalid={ this.state.one_time_paymentState === "invalid" }
                            onChange={e => this.onInputChange(e, "one_time_payment") }
                        />

                        <div className="valid-feedback">Looks good!</div>

                    </FormGroup>

                    <div className="text-right">
                        <button
                            disabled={!this.state.canSave}
                            onClick={this.onChargePayment}
                            className="btn btn-success"
                        >
                            Charge Payment
                        </button>
                    </div>

                </CardBody>
            </Card>

        )

    }

}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

ChargeOneTimePayment.propTypes = {
    case: PropTypes.object.isRequired,
    account_vaults: PropTypes.array.isRequired,
    invoices: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, '')(ChargeOneTimePayment);
