
import React from "react";
import { Col, Row } from 'reactstrap';

class SignSignatureProgress extends React.Component {

    state = {
        progress: 1
    }

    render() {

        const { progress, loaded, toggleModalFinishField, onPreview } = this.props;
        
        if(!loaded) return <div />

        return (

            <div className="doc-progress bg-dark">
                <Row>
                    <Col xs={6} className="align-self-center">
                        <p className="text-sm mb-0 text-white">
                            <span onClick={toggleModalFinishField} className="cursor-pointer">
                                Progress:{' '}
                                <span className={progress.total === progress.finished ? 'text-success font-weight-bold' : ''}>
                                {parseInt((progress.finished / progress.total) * 100)}%
                                </span>
                            </span>
                        </p>
                    </Col>

                    <Col xs={6} className="align-self-center text-right">
                        {progress.finished === 0 ? (
                            <button onClick={toggleModalFinishField} className="btn btn-success">Get Started </button>
                        ) : progress.finished < progress.total ? (
                            <button onClick={toggleModalFinishField} className="btn btn-success">Next Question </button>
                        ) : (
                            <span className="position-relative d-inline-block">
                                <i className="fas fa-arrow-down confirmation-arrow " />
                                <button onClick={onPreview} className="btn btn-success">Review </button>
                            </span>
                        )}
                    </Col>
                </Row>
            </div>

        );
    }
}

export default SignSignatureProgress
