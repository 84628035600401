import { architeckCall } from 'database';

export default (email) => {
    return new Promise (async resolve => {
    
        const createCode = await architeckCall({
            shouldNotShowAndLogError: true,
            method:'post',
            url: '/api/v1/auth/contact/security_code/create',
            data: { email }
        })

        resolve(createCode)
    
    })
}