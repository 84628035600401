import * as actionTypes from '../../actions';
import store from '../../index';

export const setViewingUserFromAdmin = async(contact) => {
    return new Promise( async resolve => {


        store.dispatch({ type: actionTypes.SET_VIEWING_USER, payload: { objects: {...contact, loggedInAsAdmin: true} } });
        resolve({success: true});

    })

}
