import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { Container, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { Redirect, Link } from 'react-router-dom';
import Circle from 'components/markup/loading/Circle'

import _documents from '_functions/documents';
import _cases from '_functions/cases';

import ModalMissingDocs from './ModalMissingDocs'
import { toggleStandardLoader } from 'store/functions/system/system';

import onDownloadDocument from '_functions/documents/download'


class Documents extends React.Component {

    state = {
        invoices: null,
        documents: null,
        case: null,
        showModal: false
    }

    refreshInterval = null;

    onFinalUpload = () => {
        toggleStandardLoader(false)
        this.setState({showModal: false})
    }

    onUploadStarted = () => {
        toggleStandardLoader(true)
    }

    onSuccess = (file, call) => {

        let _case = Object.assign({}, this.state.case)
        const missing_docs = _case.missing_docs

        const found_doc = missing_docs.find(doc => doc._id === this.state.missing_doc._id)

        if(found_doc) {

            let documents = found_doc.documents ? found_doc.documents : []
            documents.push(call.data)

            found_doc.documents = documents

            this.setState({case: {..._case, missing_docs}, showModal: false})
            this.onSaveMissingDocs(missing_docs)

        }

    }

    toggleModal = (missing_doc) => this.setState({showModal: !this.state.showModal, missing_doc})

    onSaveMissingDocs = async  (missing_docs) => {

        _cases.update(this.state.case._id, { missing_docs })

    }

    // get everything after . or return "Media Object"
    getDocumentName = (link) => {

        if(!link) return 'Misc Document'

        const arr = link.split('/')

        return arr ? arr[arr.length -1] : 'Misc Document'

    }

    redirect = () => this.setState({shouldRedirect: '/dashboard'})

    findCase = async () => {
        const _case = await _cases.findById(this.props.match.params._id)
        if(_case.success) {

            let required_documents = _case.data.missing_docs ? _case.data.missing_docs.filter(d => !d.status) : []
            let finished_documents = _case.data.missing_docs ? _case.data.missing_docs.filter(d => d.status === 'completed') : []
            this.setState({case: _case.data, required_documents, finished_documents})

        }

    }

    findDocuments = async () => {

        const found_documents = await _documents.findByCase(this.props.match.params._id)

        if(found_documents.success) {

            if(found_documents) return this.setState({documents: found_documents.data})

        }

    }

    onTemplateDocAdded = (data) => {

        const _case = Object.assign({}, this.state.case)
        if(!_case) return;

        if(data.data.document.case === _case._id) {

            const missing_docs = [..._case.missing_docs];
            const foundDoc = missing_docs.find(d => d.name === data.data.templateDocName);

            if(foundDoc) {
                foundDoc.documents.push(data.data.document);

                _case.missing_docs = missing_docs
                this.setState({case: _case})
            }

        }

    }

    onFocus = () => {
        this.refreshInterval = setInterval(() => {
            this.findCase()
        }, 2000)
    }

    onBlur = () => {
        if(this.refreshInterval) clearInterval(this.refreshInterval)
    }

    componentWillUnmount = () => {
        window.removeEventListener('focus', this.onFocus);
        window.removeEventListener('blur', this.onBlur);
        this.onBlur();
    }

    componentDidMount = async() => {

        this.findCase()
        this.findDocuments()

        this.onFocus();

        window.addEventListener('focus', this.onFocus);
        window.addEventListener('blur', this.onBlur);

    }

    render() {

        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }

        const _case = this.state.case;
        const { documents, showModal, missing_doc } = this.state;

        // if data hasn't loaded return nothing
        if(!_case || !documents) return (
            <div className="py-6"><Circle /></div>
        )

        return (

            <Container>

                <Helmet>
                    <title>Cases View</title>
                    <meta name="description" content="Cases View" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span>Cases</span>} breadcrumb_1={_case.name}
                    actionComponent={(
                        <Link to={`/dashboard/cases/view/${_case._id}`} className="btn btn-outline-success">
                            <i className="fas fa-arrow-left mr-2" />View Case
                        </Link>
                    )}
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Help Center</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm">
                            This screen allows you to review all required documents for your case. If a document type is marked as "completed" then it has been approved and no further action is needed. If a document is marked as missing it means that it is your responsibility to upload all documents needed for the category. Note that after you upload documents it may take a few days for them to be reviewed and approved.
                        </p>
                    </CardBody>

                </Card>


                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Case Documents</CardTitle>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table table-hover">

                            <thead>
                                <tr>
                                    <th>Document Type</th>
                                    <th>Uploads</th>
                                    <th>Status</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>

                            <tbody>

                            {_case.missing_docs.length ? _case.missing_docs.map((doc, i) => (
                                doc.status === 'n/a' ? null : (
                                    <tr key={i}>
                                        <td>{doc.name}</td>
                                        <td style={{whiteSpace: 'pre'}}>
                                        {doc.documents && doc.documents.length ? doc.documents.map((document, i) => (
                                          
                                               <div key={i}> 
                                                     <span onClick={() => onDownloadDocument(document)} className="cursor-pointer">
                                                        <i key={i} className="fas fa-file-alt mr-3 cursor-pointer text-warning" />
                                                         <span className="text-uppercase">
                                                            {document.friendly_name.length > 30 ? 
                                                                document.friendly_name.slice(0,30) + '...' : 
                                                                document.friendly_name
                                                            }
                                                         </span>
                                                     </span>
                                                </div>
                                          
                                        )) : '-'}
                                        </td>
                                        <td>
                                            {doc.status === 'completed' ?
                                                <span style={{whiteSpace: 'pre'}} className="text-success">Completed</span> :
                                                <span style={{whiteSpace: 'pre'}} className="text-warning">Still Needed</span>

                                        }</td>
                                        <td className="text-right">
                                            <button
                                                onClick={() => this.toggleModal(doc)}
                                                disabled={doc.status === 'completed' ? true : false}
                                                className={doc.status === 'completed' ? "btn btn-outline-success" : "btn btn-success"}
                                            >
                                                Upload Document(s)
                                            </button>
                                        </td>
                                    </tr>
                                )
                            )) : (
                                <tr>
                                    <td className="text-warning font-weight-bold">There are no documents to be uploaded at this time.</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                            </tbody>

                        </table>
                    </div>

                </Card>

                <ModalMissingDocs
                    case={_case}
                    missing_doc={missing_doc}
                    showModal={showModal}
                    toggleModal={this.toggleModal}
                    onSuccess={this.onSuccess}
                    onUploadStarted={this.onUploadStarted}
                    onFinalUpload={this.onFinalUpload}
                />


            </Container>

        );
    }
}

export default Documents
