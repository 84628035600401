import React, { Component } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Container, Row, Col } from 'reactstrap';
import keys from 'keys';

import _template_docs_sent_events from '_functions/template_docs_sent_events'

class Download extends Component {


    state = {

    };

    onDownload = () => {
        _template_docs_sent_events.create({
            template_docs_sent: this.props.template_doc_sent_id,
            contact: this.props.contact,
            event: 'Downloaded Signed Copy'
        })
    }

    render() {

        const { document_base_64 } = this.props

        return (

            <>


            <Card className="bg-dark" style={{marginBottom: 5}}>
                <CardBody>
                    <Row>
                        <Col xs={6} className="align-self-center">
                            <img src={keys.LOGO} alt="Logo" style={{maxWidth: '100%', width: 200}} />
                        </Col>
                        <Col xs={6} className="text-right align-self-center">
                            <h2 className="text-white  mb-0">Confirmation</h2>
                        </Col>
                    </Row>
                </CardBody>
            </Card>


            <Card>
                <CardBody>
                    <p className="text-sm text-center text-sm mb-0">You're All Done!.</p>
                </CardBody>
            </Card>
            
            <Container className="archk-template-docs-container">
                <Card style={{maxWidth: 600, margin: 'auto'}}>

                    <CardHeader>
                        <CardTitle className="mb-0">Document Signed Successfully</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm mb-0">You document was signed successfully. Click the button below to download a copy of your signed contract</p>

                    </CardBody>

                    <CardFooter className="text-right">
                        <span onClick={this.onDownload}>
                            <a a download="Contracted Signed.pdf" href={`data:application/pdf;base64,${document_base_64}`} className="btn btn-success">Download Document</a>
                        </span>
                    </CardFooter>

                </Card>
            </Container>

        </>

        )

    }

}

export default Download;
